import { useEffect, useLayoutEffect } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import { VideoSize } from '@peacock-libs/core'

import { useCreateDraftMutation } from '#~/graphql.generated.js'
import { MainRoutePath } from '#~/modules/main/mainRoute.js'

export default function CreateVideo() {
  const [searchParams] = useSearchParams()

  const name = searchParams.get('name') as string | undefined
  const videoSize = searchParams.get('videoSize')! as unknown as VideoSize
  const layout = searchParams.get('layout') as string | undefined
  const videoId = searchParams.get('videoId') as string | undefined

  const navigate = useNavigate()

  const [createDraft, { data, loading }] = useCreateDraftMutation()

  useLayoutEffect(() => {
    void createDraft({
      variables: {
        input: {
          name,
          videoSize,
          layout,
          videoId,
        },
      },
    })
  }, [])

  useEffect(() => {
    if (loading || !data) {
      return
    }
    const draftId = data.createDraft.id

    navigate(
      generatePath(
        draftId ? MainRoutePath : '/',
        draftId ? { id: draftId } : undefined,
      ),
      {
        replace: true,
      },
    )
  }, [loading])

  return null
}
