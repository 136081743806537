import type { DefaultOptions } from '@apollo/client'
import { ApolloClient, ApolloProvider, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { enableMapSet } from 'immer'
import { createInstance } from '@featurevisor/sdk'
import { FeaturevisorProvider } from '@featurevisor/react'

import './index.scss'
import cache from './cache.js'
import { CreateVideoRoute } from './modules/createVideo/createVideoRoute.js'
import { MainRouteComponent } from './modules/main/mainRoute.js'
import { NotFoundRoute } from './modules/notFound/notFoundRoute.js'
import reportWebVitals from './reportWebVitals.js'
import { getUserToken } from './utils/authentication.js'
import { SentryRoutes } from '#~/utils/sentryInit.js'

// @ts-expect-error cannot find module through ts-info
import productionDatafile from '@peacock-libs/featurevisor/production/datafile-tag-all.json'
// @ts-expect-error cannot find module through ts-info
import previewDatafile from '@peacock-libs/featurevisor/preview/datafile-tag-all.json'

const FEATURE_VISOR_ENVIRONMENT = import.meta.env
  .REACT_APP_FEATURE_VISOR_ENVIRONMENT

enableMapSet()

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    // fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    // fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const featurevisor = createInstance({
  datafile:
    FEATURE_VISOR_ENVIRONMENT === 'production'
      ? productionDatafile
      : previewDatafile,
})

const authLink = setContext((_, { headers }) => {
  const token = getUserToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const httpLink = new HttpLink({
  uri: `${import.meta.env.REACT_APP_GRAPHQL_BACKEND}/graphql`,
})

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
  credentials: 'include',
  connectToDevTools: import.meta.env.NODE_ENV === 'development',
  defaultOptions,
})

const container = document.querySelector('#root')!

const root = createRoot(container)

root.render(
  <ApolloProvider client={client}>
    <FeaturevisorProvider instance={featurevisor}>
      <Router
        basename={
          import.meta.env.REACT_APP_BUILD !== 'production'
            ? undefined
            : 'editor'
        }
      >
        <SentryRoutes>
          {CreateVideoRoute.Component}
          {MainRouteComponent}
          {NotFoundRoute.Component}
        </SentryRoutes>
      </Router>
    </FeaturevisorProvider>
  </ApolloProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
