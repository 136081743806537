import { Route } from 'react-router-dom'
import CreateVideo from './scenes/CreateVideo'

export class CreateVideoRoute {
  static Path = '/create'

  static Component = (
    <Route path={CreateVideoRoute.Path} element={<CreateVideo />} />
  )
}
