/**
 * Function taken from Apollo Client source code.
 *
 * https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
 */

import type { FieldPolicy, Reference } from '@apollo/client'

export const ITEM_PER_PAGE = 30

type KeyArgs = FieldPolicy['keyArgs']

// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export default function pageLimitPagination<T = Reference>(
  limit = ITEM_PER_PAGE,
  keyArgs: KeyArgs = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      let merged: T[] = []

      if (args?.input?.page) {
        merged = existing ? existing.slice(0) : []

        // Assume an offset of 0 if args.offset omitted.
        const { page = 0 } = args.input

        for (let i = 0; i < incoming.length; ++i) {
          merged[page * limit + i] = incoming[i]
        }
      } else {
        // It's unusual (probably a mistake) for a paginated field not
        // to receive any arguments, so you might prefer to throw an
        // exception here, instead of recovering by appending incoming
        // onto the existing array.
        // eslint-disable-next-line prefer-spread
        merged.push.apply(merged, incoming as T[])
      }

      return merged
    },
  }
}
