import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T
export type InputMaybe<T> = T
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  NewDateTimeResolver: any
  /** Floats that will have a value greater than 0. */
  PositiveFloat: any
  /** Integers that will have a value greater than 0. */
  PositiveInt: any
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export type Access = {
  __typename?: 'Access'
  analytics?: Maybe<AccessType>
  assets?: Maybe<AccessType>
  settings?: Maybe<AccessType>
  users?: Maybe<AccessType>
}

export type AccessType = {
  __typename?: 'AccessType'
  access?: Maybe<Scalars['Boolean']>
  permissions?: Maybe<Scalars['String']>
}

export type AddAnimationsInput = {
  categories: Array<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  name?: Scalars['String']
  postStyle: Array<Scalars['String']>
  previewUrl: Scalars['String']
  soundUrl: Scalars['String']
  tags: Array<Scalars['String']>
}

export type AddBrandColorInput = {
  color: Scalars['String']
}

export type AddMediaInput = {
  fileExtension?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
  fileSize?: InputMaybe<Scalars['Float']>
  mediaType: Scalars['String']
  mimeType?: InputMaybe<Scalars['String']>
  originalFileName?: InputMaybe<Scalars['String']>
  uniqueId: Scalars['String']
  url?: InputMaybe<Scalars['String']>
}

export type AddMediaResponse = {
  __typename?: 'AddMediaResponse'
  id?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  presignedUrl?: Maybe<Scalars['String']>
  uploadStatus: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  line2?: Maybe<Scalars['String']>
  zipcode?: Maybe<Scalars['String']>
}

export type AddressInput = {
  businessName?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  line1?: InputMaybe<Scalars['String']>
  line2?: InputMaybe<Scalars['String']>
  vatNumber?: InputMaybe<Scalars['String']>
  zipcode?: InputMaybe<Scalars['String']>
}

export type AdminTemplateRemoveInput = {
  templateId: Scalars['String']
}

export type AdminTemplatesInput = {
  duration?: InputMaybe<Scalars['String']>
  featured?: InputMaybe<Scalars['String']>
  first?: Scalars['Float']
  lastPublish?: InputMaybe<Scalars['String']>
  searchWord?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
  status?: InputMaybe<Scalars['String']>
}

export type Animation = {
  __typename?: 'Animation'
  _id: Scalars['String']
  audioUrl?: Maybe<Scalars['String']>
  categories: Array<Scalars['String']>
  createdAt?: Maybe<Scalars['NewDateTimeResolver']>
  description: Scalars['String']
  duration?: Maybe<Scalars['Float']>
  height?: Maybe<Scalars['Float']>
  name: Scalars['String']
  postStyle: Array<Scalars['String']>
  tags: Array<Scalars['String']>
  thumbnail: Scalars['String']
  usedCount?: Maybe<Scalars['Float']>
  videoUrl: Scalars['String']
  webm: Scalars['String']
  width?: Maybe<Scalars['Float']>
}

export type AnimationPreviewInput = {
  animationId: Scalars['String']
}

export type AnimationRemoveInput = {
  animationId: Scalars['String']
}

export type AnimationTrackInput = {
  animationId: Scalars['String']
}

export type AnimationsFilterInput = {
  category?: InputMaybe<Array<Scalars['String']>>
  featured?: InputMaybe<Scalars['String']>
  first?: Scalars['Float']
  lastLogin?: InputMaybe<Scalars['String']>
  searchWord?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
  type?: InputMaybe<Scalars['String']>
}

export type AnimationsFiltered = {
  __typename?: 'AnimationsFiltered'
  _id: Scalars['String']
  createdAt?: Maybe<Scalars['NewDateTimeResolver']>
  description: Scalars['String']
  isFeatured?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  postStyle?: Maybe<Array<Scalars['String']>>
  thumbnail?: Maybe<Scalars['String']>
  usedCount?: Maybe<Scalars['Float']>
}

export type AnimationsFilteredCount = {
  __typename?: 'AnimationsFilteredCount'
  length: Scalars['Float']
}

export type BrandColorMessageResponse = {
  __typename?: 'BrandColorMessageResponse'
  index?: Maybe<Scalars['String']>
  message: Scalars['String']
  status: Scalars['String']
}

export type CancelSubscriptionInput = {
  comment: Scalars['String']
  optionId: Scalars['String']
}

export type CancelationMessageResponse = {
  __typename?: 'CancelationMessageResponse'
  message: Scalars['String']
}

export type CancelationOptions = {
  __typename?: 'CancelationOptions'
  _id: Scalars['String']
  definition: Scalars['String']
  name: Scalars['String']
}

export type CanceledOptionInput = {
  definition: Scalars['String']
  name: Scalars['String']
}

export type CanceledOptionRemoveInput = {
  id: Scalars['String']
  remove?: InputMaybe<Scalars['Boolean']>
}

export type CanceledOptionUpdateInput = {
  definition: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
}

export type CanceledUserAddInput = {
  canceledUserId: Scalars['String']
  reason: Scalars['String']
  reasonId: Scalars['String']
}

export type CanceledUserRemoveInput = {
  canceledUserId: Scalars['String']
}

export type CanceledUserSearchInput = {
  dateRange?: Scalars['String']
  first?: Scalars['Float']
  reasonBy?: Scalars['String']
  search?: Scalars['String']
  skip?: Scalars['Float']
}

export type CanceledUsers = {
  __typename?: 'CanceledUsers'
  _id: Scalars['String']
  comment: Scalars['String']
  optionId: Scalars['String']
  planData?: Maybe<Array<PlanData>>
  reason: Scalars['String']
  totalPayments?: Maybe<Scalars['Float']>
  updateDate: Scalars['NewDateTimeResolver']
  userData: Array<UserFiltered>
}

export type CancelledUsersCount = {
  __typename?: 'CancelledUsersCount'
  cancelledUsersCount: Scalars['String']
  subscriptionSaved: Scalars['String']
}

export type CancelledUsersFilteredCount = {
  __typename?: 'CancelledUsersFilteredCount'
  length: Scalars['Float']
}

export type CategoriesInput = {
  id?: InputMaybe<Scalars['String']>
  key?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type CategoryMessageResponse = {
  __typename?: 'CategoryMessageResponse'
  message: Scalars['String']
}

export type ChangePasswordInput = {
  gRecaptchaToken: Scalars['String']
  gRecaptchaType: Scalars['String']
  newPassword: Scalars['String']
  newPasswordRepeat: Scalars['String']
  token: Scalars['String']
}

export type Colors = {
  __typename?: 'Colors'
  _id: Scalars['String']
  color: Scalars['String']
  palettes?: Maybe<Array<Scalars['String']>>
}

export type ColorsInput = {
  first?: Scalars['Float']
  search?: InputMaybe<Scalars['String']>
  section?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
}

export type ConsumeCreditInput = {
  videoVersionId: Scalars['String']
}

export type CouponInput = {
  coupon: Scalars['String']
}

export type CouponResponse = {
  __typename?: 'CouponResponse'
  durationInMonth?: Maybe<Scalars['String']>
  percentOff?: Maybe<Scalars['Float']>
  products?: Maybe<Array<Scalars['String']>>
  status: Scalars['String']
}

export type CreateDraftInput = {
  layout?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  videoId?: InputMaybe<Scalars['String']>
  videoSize?: InputMaybe<Scalars['String']>
}

export type CreateVideoVersionInput = {
  videoId: Scalars['String']
}

export type Credits = {
  __typename?: 'Credits'
  credits: Scalars['String']
  id: Scalars['String']
  name: Scalars['String']
  price: Scalars['String']
}

export type CustomizeTemplateInput = {
  templateId: Scalars['String']
}

export type DeprecatedTemplates = {
  __typename?: 'DeprecatedTemplates'
  _id: Scalars['String']
  description: Scalars['String']
  duration: Scalars['String']
  isFavourite?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  thumbnail?: Maybe<Scalars['String']>
  videoUrl: Scalars['String']
}

export type DeprecatedTemplatesFilterInput = {
  duration?: InputMaybe<Scalars['String']>
  featured?: InputMaybe<Scalars['String']>
  first?: Scalars['Float']
  lastPublish?: InputMaybe<Scalars['String']>
  searchWord?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
  status?: InputMaybe<Scalars['String']>
}

export type DeprecatedTemplatesFiltered = {
  __typename?: 'DeprecatedTemplatesFiltered'
  _id: Scalars['String']
  createDate?: Maybe<Scalars['NewDateTimeResolver']>
  description?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['String']>
  featuredBy?: Maybe<Scalars['String']>
  isFeatured?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  status?: Maybe<Scalars['String']>
  thumbnail: Scalars['String']
  usedCount?: Maybe<Scalars['Float']>
}

export type DeprecatedTemplatesFilteredCount = {
  __typename?: 'DeprecatedTemplatesFilteredCount'
  length: Scalars['Float']
}

export type Directories = {
  __typename?: 'Directories'
  folderName?: Maybe<Scalars['String']>
  lastUpdate?: Maybe<Scalars['String']>
  videos?: Maybe<Scalars['Float']>
}

export type DirectoryInput = {
  name: Scalars['String']
}

export type DirectoryRenameInput = {
  newName: Scalars['String']
  oldName: Scalars['String']
}

export type DraftAddInput = {
  draft?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['Float']>
  layout?: InputMaybe<Scalars['Float']>
  path?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  videoId?: InputMaybe<Scalars['String']>
  videoSize?: InputMaybe<Scalars['String']>
}

export type DraftDuplicateInput = {
  draftId: Scalars['String']
}

export type DraftRemoveInput = {
  draftId: Scalars['String']
}

export type Drafts = {
  __typename?: 'Drafts'
  _id: Scalars['String']
  createDate?: Maybe<Scalars['NewDateTimeResolver']>
  draft?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['Float']>
  isTemplate?: Maybe<Scalars['Boolean']>
  layout: Scalars['Float']
  renderData?: Maybe<VideoVersion>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updateDate?: Maybe<Scalars['NewDateTimeResolver']>
  videoSize: Scalars['String']
}

export type DraftsGetInput = {
  draftId?: InputMaybe<Scalars['String']>
  keyword?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
}

export type DraftsMessageResponse = {
  __typename?: 'DraftsMessageResponse'
  index?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  status: Scalars['String']
}

export type DuplicateTemplateInput = {
  templateId: Scalars['String']
}

export type ElementsObjectsData = {
  __typename?: 'ElementsObjectsData'
  _id: Scalars['String']
  categories?: Maybe<Array<Scalars['String']>>
  height: Scalars['String']
  mainUrl: Scalars['String']
  name: Scalars['String']
  thumbUrl: Scalars['String']
  width: Scalars['String']
}

export type ElementsShapesData = {
  __typename?: 'ElementsShapesData'
  _id: Scalars['String']
  categories?: Maybe<Array<Scalars['String']>>
  height: Scalars['String']
  mainUrl: Scalars['String']
  name: Scalars['String']
  subType: Scalars['String']
  svg?: Maybe<Scalars['String']>
  thumbUrl: Scalars['String']
  width: Scalars['String']
}

export type FavouriteAddInput = {
  id: Scalars['String']
  type: Scalars['String']
}

export type FavouriteMusic = {
  __typename?: 'FavouriteMusic'
  _id: Scalars['String']
  melodie?: Maybe<MelodieObject>
  referenceId: Scalars['String']
  type: Scalars['String']
  uploaded?: Maybe<Array<FavUploaded>>
  userId: Scalars['String']
}

export type FavouriteMusicAddInput = {
  element?: InputMaybe<MelodieInput>
  id: Scalars['String']
  type: Scalars['String']
}

export type FavouriteMusicInput = {
  first?: Scalars['Float']
  skip?: Scalars['Float']
}

export type FavouriteMusicRemoveInput = {
  id: Scalars['String']
  type: Scalars['String']
}

export type FavouriteRemoveInput = {
  id: Scalars['String']
  type: Scalars['String']
}

export type FavouriteResult = PeacockVideos | Template

export type Featured = {
  __typename?: 'Featured'
  featuredBy?: Maybe<User>
  isFeatured?: Maybe<Scalars['Boolean']>
}

export type FontUseInput = {
  brandFont?: InputMaybe<Scalars['Boolean']>
  fontId: Scalars['String']
  recentlyUsed?: InputMaybe<Scalars['Boolean']>
}

export type Fonts = {
  __typename?: 'Fonts'
  _id: Scalars['String']
  family?: Maybe<Scalars['String']>
  fontsUrl?: Maybe<Scalars['String']>
  imageExampleUrl?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  isPremium?: Maybe<Scalars['Boolean']>
  source?: Maybe<Scalars['String']>
  svg?: Maybe<Scalars['String']>
  useDate?: Maybe<Scalars['String']>
}

export type FontsInput = {
  first?: Scalars['Float']
  search?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
  type?: InputMaybe<Scalars['String']>
}

export type FontsMessageResponse = {
  __typename?: 'FontsMessageResponse'
  message: Scalars['String']
  status: Scalars['String']
  type: Scalars['String']
}

export type GeneralResponseWithMessage = {
  __typename?: 'GeneralResponseWithMessage'
  message: Scalars['String']
  status?: Maybe<Scalars['String']>
  statusCode?: Maybe<Scalars['Float']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetDeprecatedTemplatesResponse = {
  __typename?: 'GetDeprecatedTemplatesResponse'
  animations: Array<Animation>
  featured: Array<DeprecatedTemplates>
  new: Array<DeprecatedTemplates>
  popular: Array<DeprecatedTemplates>
}

export type GetDirectoryInput = {
  path?: Scalars['String']
}

export type GetSocialAccountResponse = {
  __typename?: 'GetSocialAccountResponse'
  facebook: SocialAccountData
  instagram: SocialAccountData
  tiktok: SocialAccountData
  youtube: SocialAccountData
}

export type GetTemplateInput = {
  videoId: Scalars['String']
}

export type Giphy = {
  __typename?: 'Giphy'
  height: Scalars['String']
  id: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
  width: Scalars['String']
}

export type GiphyFilterInput = {
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Float']>
  page?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<Scalars['String']>
}

export type Invoice = {
  __typename?: 'Invoice'
  amount?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['NewDateTimeResolver']>
  paid?: Maybe<Scalars['Boolean']>
  url?: Maybe<Scalars['String']>
}

export type LatestVideoVersionInput = {
  videoId: Scalars['String']
}

export type ListMediaInput = {
  favourite?: InputMaybe<Scalars['Boolean']>
  first?: Scalars['Float']
  ids?: InputMaybe<Array<Scalars['String']>>
  mediaType: Scalars['String']
  skip?: Scalars['Float']
}

export type ListedMedia = {
  __typename?: 'ListedMedia'
  _id: Scalars['String']
  duration?: Maybe<Scalars['PositiveFloat']>
  favourite?: Maybe<Scalars['Boolean']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['PositiveInt']>
  mediaType: Scalars['String']
  name?: Maybe<Scalars['String']>
  progress?: Maybe<Scalars['Float']>
  ratio?: Maybe<Scalars['String']>
  thumbnailUrl?: Maybe<Scalars['String']>
  uniqueId?: Maybe<Scalars['String']>
  uploadStatus?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['PositiveInt']>
}

export type LoginInput = {
  email: Scalars['String']
  gRecaptchaToken: Scalars['String']
  gRecaptchaType: Scalars['String']
  password: Scalars['String']
}

export type LoginResponse = {
  __typename?: 'LoginResponse'
  accessToken: Scalars['String']
  user: User
}

export type MelodieAudio = {
  __typename?: 'MelodieAudio'
  bpm: Scalars['Float']
  description: Scalars['String']
  duration: Scalars['Float']
  favourite: Scalars['Boolean']
  id: Scalars['String']
  title: Scalars['String']
  url: Scalars['String']
  waveformUrl: Scalars['String']
}

export type MelodieAudioInput = {
  bpm_from?: InputMaybe<Scalars['Float']>
  bpm_to?: InputMaybe<Scalars['Float']>
  duration_from?: InputMaybe<Scalars['Float']>
  duration_to?: InputMaybe<Scalars['Float']>
  favourite?: InputMaybe<Scalars['Boolean']>
  genre?: InputMaybe<Scalars['String']>
  instrument?: InputMaybe<Scalars['String']>
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Float']>
  mood?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Float']>
}

export type MelodieInput = {
  bpm?: InputMaybe<Scalars['Float']>
  duration?: InputMaybe<Scalars['Float']>
  id: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

export type MelodieObject = {
  __typename?: 'MelodieObject'
  bpm?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addAnimation: GeneralResponseWithMessage
  addCancelationOption: CancelationMessageResponse
  addCanceledUser: CancelationMessageResponse
  addCategory: CategoryMessageResponse
  addDirectory: Directories
  addFavourite: FavouriteResult
  addFavouriteMusic: GeneralResponseWithMessage
  addMedia: AddMediaResponse
  addRecent: Recents
  applyCancelDiscount: GeneralResponseWithMessage
  archiveDirectory: Directories
  cancelIncompleteSubscription: GeneralResponseWithMessage
  cancelSubscription: GeneralResponseWithMessage
  changeFontData: FontsMessageResponse
  changePassword: Scalars['Boolean']
  consumeCredit: GeneralResponseWithMessage
  createDraft: Drafts
  createVideoVersion: VideoVersionResponse
  customizeTemplate: Drafts
  duplicateDraft: DraftsMessageResponse
  duplicateTemplate: Template
  login: LoginResponse
  postVideoOnSocial: SocialApiResponse
  register: User
  removeAdminTemplate: Scalars['Boolean']
  removeAnimation: GeneralResponseWithMessage
  removeBrandColor: BrandColorMessageResponse
  removeCancelationOption: CancelationMessageResponse
  removeCanceledUser: CancelationMessageResponse
  removeCategory: CategoryMessageResponse
  removeDirectory: Directories
  removeDraft: DraftsMessageResponse
  removeFavourite: FavouriteResult
  removeFavouriteMusic: GeneralResponseWithMessage
  removeMedia: ListedMedia
  removeMediaBackground: Scalars['String']
  removePlatformText: PlatformMessageResponse
  removePremiumElement: DraftsMessageResponse
  removeRecent: Recents
  removeSocialAccount: SocialApiResponse
  renameDirectory: Directories
  renewSubscription: GeneralResponseWithMessage
  restoreDirectory: Directories
  saveAPIToken: SocialApiResponse
  saveBrandColor: BrandColorMessageResponse
  saveDraft: Drafts
  saveTemplate: Template
  setUpIntent: TokenResponse
  subUserLogin: SubUserLoginResponse
  subscriptionIntent: SubscriptionTokenResponse
  trackAnimationUsedCount: TrackAnimationMessageResponse
  trackColorUsedCount: TrackColorMessageResponse
  trackElementUsedCount: GeneralResponseWithMessage
  updateAnimation: GeneralResponseWithMessage
  updateBillingAddress: GeneralResponseWithMessage
  updateCancelationOption: CancelationMessageResponse
  updateCategory: CategoryMessageResponse
  updatePlatformText: PlatformMessageResponse
  updateUser: User
  updateUsersData: User
  usersDelete: GeneralResponseWithMessage
  /** Send reset password email and start the password changing flow. */
  validate: Scalars['Boolean']
  validateResetPassword: Scalars['Boolean']
  verify: GeneralResponseWithMessage
  verifyResetPassword: Scalars['Boolean']
  videoCreditPaymentIntent: TokenResponse
}

export type MutationAddAnimationArgs = {
  input: AddAnimationsInput
  thumbnailImage?: InputMaybe<Scalars['Upload']>
}

export type MutationAddCancelationOptionArgs = {
  input?: InputMaybe<CanceledOptionInput>
}

export type MutationAddCanceledUserArgs = {
  input?: InputMaybe<CanceledUserAddInput>
}

export type MutationAddCategoryArgs = {
  input?: InputMaybe<CategoriesInput>
}

export type MutationAddDirectoryArgs = {
  input: DirectoryInput
}

export type MutationAddFavouriteArgs = {
  input: FavouriteAddInput
}

export type MutationAddFavouriteMusicArgs = {
  input: FavouriteMusicAddInput
}

export type MutationAddMediaArgs = {
  input: AddMediaInput
}

export type MutationAddRecentArgs = {
  input: AddRecentsInput
}

export type MutationArchiveDirectoryArgs = {
  input: DirectoryInput
}

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

export type MutationChangeFontDataArgs = {
  input: FontUseInput
}

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MutationConsumeCreditArgs = {
  input: ConsumeCreditInput
}

export type MutationCreateDraftArgs = {
  input: CreateDraftInput
}

export type MutationCreateVideoVersionArgs = {
  input: CreateVideoVersionInput
}

export type MutationCustomizeTemplateArgs = {
  input: CustomizeTemplateInput
}

export type MutationDuplicateDraftArgs = {
  input: DraftDuplicateInput
}

export type MutationDuplicateTemplateArgs = {
  input: DuplicateTemplateInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationPostVideoOnSocialArgs = {
  input: PostSocialMediaInput
}

export type MutationRegisterArgs = {
  input: RegisterInput
}

export type MutationRemoveAdminTemplateArgs = {
  input: AdminTemplateRemoveInput
}

export type MutationRemoveAnimationArgs = {
  input: AnimationRemoveInput
}

export type MutationRemoveBrandColorArgs = {
  input: AddBrandColorInput
}

export type MutationRemoveCancelationOptionArgs = {
  input: CanceledOptionRemoveInput
}

export type MutationRemoveCanceledUserArgs = {
  input?: InputMaybe<CanceledUserRemoveInput>
}

export type MutationRemoveCategoryArgs = {
  input?: InputMaybe<CategoriesInput>
}

export type MutationRemoveDirectoryArgs = {
  input: DirectoryInput
}

export type MutationRemoveDraftArgs = {
  input: DraftRemoveInput
}

export type MutationRemoveFavouriteArgs = {
  input: FavouriteRemoveInput
}

export type MutationRemoveFavouriteMusicArgs = {
  input: FavouriteMusicRemoveInput
}

export type MutationRemoveMediaArgs = {
  input: RemoveMediaInput
}

export type MutationRemoveMediaBackgroundArgs = {
  input: RemoveBackgroundInput
}

export type MutationRemovePlatformTextArgs = {
  input: PlatformTextDeleteInput
}

export type MutationRemovePremiumElementArgs = {
  input: RemovePremiumElementsInput
}

export type MutationRemoveRecentArgs = {
  input: RemoveRecentsInput
}

export type MutationRemoveSocialAccountArgs = {
  input: RemoveSocialAccountInput
}

export type MutationRenameDirectoryArgs = {
  input: DirectoryRenameInput
}

export type MutationRenewSubscriptionArgs = {
  input: RenewSubscriptionInput
}

export type MutationRestoreDirectoryArgs = {
  input: DirectoryInput
}

export type MutationSaveApiTokenArgs = {
  input: SocialAccountAuthTokenInput
}

export type MutationSaveBrandColorArgs = {
  input: AddBrandColorInput
}

export type MutationSaveDraftArgs = {
  input: DraftAddInput
}

export type MutationSaveTemplateArgs = {
  input: SaveTemplateInput
}

export type MutationSubUserLoginArgs = {
  input: SubUserLoginInput
}

export type MutationSubscriptionIntentArgs = {
  input: SubscriptionInput
}

export type MutationTrackAnimationUsedCountArgs = {
  input: AnimationTrackInput
}

export type MutationTrackColorUsedCountArgs = {
  input: AddBrandColorInput
}

export type MutationTrackElementUsedCountArgs = {
  input: TrackElementInput
}

export type MutationUpdateAnimationArgs = {
  input: AddAnimationsInput
  thumbnailImage?: InputMaybe<Scalars['Upload']>
}

export type MutationUpdateBillingAddressArgs = {
  input: AddressInput
}

export type MutationUpdateCancelationOptionArgs = {
  input?: InputMaybe<CanceledOptionUpdateInput>
}

export type MutationUpdateCategoryArgs = {
  input?: InputMaybe<CategoriesInput>
}

export type MutationUpdatePlatformTextArgs = {
  input: PlatformTextAddInput
}

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserDataInput>
  picture?: InputMaybe<Scalars['Upload']>
}

export type MutationUpdateUsersDataArgs = {
  input?: InputMaybe<UserDataChangeInput>
  picture?: InputMaybe<Scalars['Upload']>
}

export type MutationUsersDeleteArgs = {
  input?: InputMaybe<UserDataDeleteInput>
}

export type MutationValidateArgs = {
  input: ValidateInput
}

export type MutationValidateResetPasswordArgs = {
  input: ValidateInput
}

export type MutationVerifyArgs = {
  input: VerifyInput
}

export type MutationVerifyResetPasswordArgs = {
  input: VerifyInput
}

export type MutationVideoCreditPaymentIntentArgs = {
  input: PaymentIntent
}

export type ObjectsCategories = {
  __typename?: 'ObjectsCategories'
  _id: Scalars['String']
  key: Scalars['String']
  name: Scalars['String']
}

export type ObjectsCategoriesWithObjects = {
  __typename?: 'ObjectsCategoriesWithObjects'
  ObjectsData: Array<ElementsObjectsData>
  _id: Scalars['String']
  createDate?: Maybe<Scalars['NewDateTimeResolver']>
  isNew?: Maybe<Scalars['Boolean']>
  key: Scalars['String']
  name: Scalars['String']
  updateDate?: Maybe<Scalars['NewDateTimeResolver']>
}

export type ObjectsInput = {
  category?: Scalars['String']
  filter?: Scalars['String']
  first?: Scalars['Float']
  keyword?: Scalars['String']
  skip?: Scalars['Float']
}

export type PaymentIntent = {
  coupon?: InputMaybe<Scalars['String']>
  payMethod?: InputMaybe<Scalars['String']>
  paymentMethodId?: InputMaybe<Scalars['String']>
  planId: Scalars['String']
}

export type PeacockVideoInput = {
  id: Scalars['String']
}

export type PeacockVideos = {
  __typename?: 'PeacockVideos'
  _id: Scalars['String']
  duration: Scalars['Float']
  gifingId: Scalars['String']
  hasAudio?: Maybe<Scalars['Boolean']>
  isFavourite: Scalars['Boolean']
  keywords?: Maybe<Scalars['String']>
  mp4url: Scalars['String']
  thumbnail: Scalars['String']
  title: Scalars['String']
  watermarkmp4: Scalars['String']
}

export type PeacockVideosCategories = {
  __typename?: 'PeacockVideosCategories'
  _id: Scalars['String']
  key: Scalars['String']
  name: Scalars['String']
}

export type PeacockVideosInput = {
  category?: Scalars['String']
  first?: Scalars['Float']
  keyword?: Scalars['String']
  skip?: Scalars['Float']
}

export type PexelsImage = {
  __typename?: 'PexelsImage'
  description: Scalars['String']
  height: Scalars['String']
  id: Scalars['String']
  preview_url: Scalars['String']
  url: Scalars['String']
  width: Scalars['String']
}

export type PexelsImageInput = {
  category?: InputMaybe<Scalars['String']>
  color?: InputMaybe<Scalars['String']>
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Float']>
  orientation?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Float']>
  sort?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type PexelsVideo = {
  __typename?: 'PexelsVideo'
  aspect_ratio: Scalars['String']
  collection?: Maybe<Scalars['String']>
  description: Scalars['String']
  duration?: Maybe<Scalars['PositiveFloat']>
  hd_url: Scalars['String']
  height?: Maybe<Scalars['PositiveInt']>
  id: Scalars['String']
  sd_url: Scalars['String']
  thumbnail: Scalars['String']
  width?: Maybe<Scalars['PositiveInt']>
}

export type PexelsVideoInput = {
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Float']>
  orientation?: InputMaybe<Scalars['String']>
  page?: InputMaybe<Scalars['Float']>
  sort?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type PlanData = {
  __typename?: 'PlanData'
  subscriptionName?: Maybe<Scalars['String']>
  subscriptionStatus?: Maybe<Scalars['String']>
  webhookStatus?: Maybe<Scalars['String']>
}

export type PlatformMessageResponse = {
  __typename?: 'PlatformMessageResponse'
  message: Scalars['String']
}

export type PlatformText = {
  __typename?: 'PlatformText'
  _id?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  animation?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  subHeadline?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type PlatformTextAddInput = {
  active?: InputMaybe<Scalars['Boolean']>
  animation?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  headline?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  page: Scalars['String']
  subHeadline?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

export type PlatformTextDeleteInput = {
  id: Scalars['String']
}

export type PlatformTextInput = {
  page: Scalars['String']
  type: Scalars['String']
}

export type PremiumElement = {
  __typename?: 'PremiumElement'
  id: Scalars['String']
  slideId: Scalars['String']
  thumbImage: Scalars['String']
  title: Scalars['String']
  type: Scalars['String']
}

export type PremiumElementsInput = {
  draftId: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  adminTemplates: Array<Template>
  adminTemplatesCount: Scalars['Float']
  canceledUsersCount: CancelledUsersCount
  checkCoupon: CouponResponse
  creditsList: Array<Credits>
  currentUser: User
  filterAnimations: Array<AnimationsFiltered>
  filterAnimationsCount: AnimationsFilteredCount
  filterCancelledUsersCount: CancelledUsersFilteredCount
  filterDeprecatedTemplates: Array<DeprecatedTemplatesFiltered>
  filterDeprecatedTemplatesCount: DeprecatedTemplatesFilteredCount
  filterFavoriteMusic: Array<FavouriteMusic>
  filterUser: Array<UserFiltered>
  filterUsersCount: UsersFilteredCount
  getAnimation: Animation
  getAuthLoginURL: SocialApiResponse
  getBrandColors: Array<Colors>
  getCancelationOptions: Array<CancelationOptions>
  getCanceledUsers: Array<CanceledUsers>
  getColors: Array<Colors>
  getColorsPalettes: Array<Colors>
  getCurrentPlan: StripeProduct
  getDeprecatedTemplates: GetDeprecatedTemplatesResponse
  getDirectories: Array<Directories>
  getDrafts: Array<Drafts>
  getGiphyAssets: Array<Giphy>
  getGradientColors: Array<Colors>
  getInvoices: Array<Invoice>
  /** Only available for PeacockRenderFaas role */
  getLatestVideoVersion: UnrenderedVideoVersionResponse
  getObjectsCategories: Array<ObjectsCategories>
  getObjectsCategoriesWithObjects: Array<ObjectsCategoriesWithObjects>
  getPeacockRelatedVideos: Array<PeacockVideos>
  getPeacockVideo: PeacockVideos
  getPeacockVideosCategories: Array<PeacockVideosCategories>
  getShapesCategoriesWithShapes: Array<ShapesCategoriesWithShapes>
  getSocialAccount: Array<SocialAccount>
  getSocialAccounts: GetSocialAccountResponse
  getStripeGroupPlans: Array<StripeProductGroup>
  getStripeTopupPlans: Array<StripeProduct>
  getSubscriptionPreview: SubscriptionPreview
  getTemplate?: Maybe<Template>
  getTemplateCategories: Array<TemplateCategory>
  getVideoVersionById: VideoVersionResponse
  getVideoVersions: Array<VideoVersion>
  listMedia: Array<ListedMedia>
  platformTexts: Array<PlatformText>
  readPremiumElements: Array<PremiumElement>
  recentsList: Array<Recents>
  searchAnimations: Array<Animation>
  searchColors: SearchColorsResponse
  /** @deprecated This query communicates with an old collection of templates that are no longer in use. Please use searchDfyTemplates instead. */
  searchDeprecatedTemplates: Array<DeprecatedTemplates>
  searchElementsObjects: Array<ElementsObjectsData>
  searchElementsShapes: Array<ElementsShapesData>
  searchFonts: Array<Fonts>
  searchMelodieAudio: Array<MelodieAudio>
  searchPeacockVideos: Array<PeacockVideos>
  searchPexelsImage: Array<PexelsImage>
  searchPexelsVideo: Array<PexelsVideo>
  searchTemplates: Array<Template>
  searchUnsplashImage: Array<Unsplash>
  statsFilter: Array<Stats>
  totalDrafts: Scalars['Float']
  userCheck: User
}

export type QueryAdminTemplatesArgs = {
  input: AdminTemplatesInput
}

export type QueryAdminTemplatesCountArgs = {
  input: AdminTemplatesInput
}

export type QueryCheckCouponArgs = {
  input: CouponInput
}

export type QueryFilterAnimationsArgs = {
  input: AnimationsFilterInput
}

export type QueryFilterAnimationsCountArgs = {
  input: AnimationsFilterInput
}

export type QueryFilterCancelledUsersCountArgs = {
  input: CanceledUserSearchInput
}

export type QueryFilterDeprecatedTemplatesArgs = {
  input: DeprecatedTemplatesFilterInput
}

export type QueryFilterDeprecatedTemplatesCountArgs = {
  input: DeprecatedTemplatesFilterInput
}

export type QueryFilterFavoriteMusicArgs = {
  input: FavouriteMusicInput
}

export type QueryFilterUserArgs = {
  input: UserFilterInput
}

export type QueryFilterUsersCountArgs = {
  input: UserFilterInput
}

export type QueryGetAnimationArgs = {
  input: AnimationPreviewInput
}

export type QueryGetAuthLoginUrlArgs = {
  input: SocialAccountLoginInput
}

export type QueryGetBrandColorsArgs = {
  input: ColorsInput
}

export type QueryGetCanceledUsersArgs = {
  input: CanceledUserSearchInput
}

export type QueryGetDirectoriesArgs = {
  input: GetDirectoryInput
}

export type QueryGetDraftsArgs = {
  input: DraftsGetInput
}

export type QueryGetGiphyAssetsArgs = {
  input: GiphyFilterInput
}

export type QueryGetLatestVideoVersionArgs = {
  input: LatestVideoVersionInput
}

export type QueryGetPeacockRelatedVideosArgs = {
  input: PeacockVideoInput
}

export type QueryGetPeacockVideoArgs = {
  input: PeacockVideoInput
}

export type QueryGetShapesCategoriesWithShapesArgs = {
  input: ShapesCategoryInput
}

export type QueryGetSocialAccountArgs = {
  input: SocialAccountInput
}

export type QueryGetSubscriptionPreviewArgs = {
  input: SubscriptionPreviewInput
}

export type QueryGetTemplateArgs = {
  input: GetTemplateInput
}

export type QueryGetVideoVersionByIdArgs = {
  input: VideoVersionInput
}

export type QueryGetVideoVersionsArgs = {
  input?: InputMaybe<VideoVersionsInput>
}

export type QueryListMediaArgs = {
  input: ListMediaInput
}

export type QueryPlatformTextsArgs = {
  input: PlatformTextInput
}

export type QueryReadPremiumElementsArgs = {
  input: PremiumElementsInput
}

export type QueryRecentsListArgs = {
  input: RecentsInput
}

export type QuerySearchAnimationsArgs = {
  input: SearchAnimationsInput
}

export type QuerySearchColorsArgs = {
  input: ColorsInput
}

export type QuerySearchDeprecatedTemplatesArgs = {
  input: SearchDeprecatedTemplatesInput
}

export type QuerySearchElementsObjectsArgs = {
  input: ObjectsInput
}

export type QuerySearchElementsShapesArgs = {
  input: ShapesInput
}

export type QuerySearchFontsArgs = {
  input: FontsInput
}

export type QuerySearchMelodieAudioArgs = {
  input: MelodieAudioInput
}

export type QuerySearchPeacockVideosArgs = {
  input: PeacockVideosInput
}

export type QuerySearchPexelsImageArgs = {
  input: PexelsImageInput
}

export type QuerySearchPexelsVideoArgs = {
  input: PexelsVideoInput
}

export type QuerySearchTemplatesArgs = {
  input: SearchTemplatesInput
}

export type QuerySearchUnsplashImageArgs = {
  input: UnsplashInput
}

export type QueryStatsFilterArgs = {
  input: StatsInput
}

export type QueryTotalDraftsArgs = {
  input: DraftsGetInput
}

export type QueryUserCheckArgs = {
  input: UserCheckInput
}

export type Recents = {
  __typename?: 'Recents'
  assetId?: Maybe<Scalars['String']>
  author?: Maybe<Scalars['String']>
  authorPage?: Maybe<Scalars['String']>
  bpm?: Maybe<Scalars['Float']>
  content: Scalars['String']
  description?: Maybe<Scalars['String']>
  duration?: Maybe<Scalars['PositiveFloat']>
  favourite?: Maybe<Scalars['Boolean']>
  height?: Maybe<Scalars['Float']>
  mediaType?: Maybe<Scalars['String']>
  ratio?: Maybe<Scalars['String']>
  source: Scalars['String']
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Float']>
}

export type RecentsInput = {
  limit?: InputMaybe<Scalars['Float']>
  mediaType?: InputMaybe<Array<Scalars['String']>>
  skip?: InputMaybe<Scalars['Float']>
  source?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Array<Scalars['String']>>
}

export type RegisterInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  gRecaptchaToken: Scalars['String']
  gRecaptchaType: Scalars['String']
  lastName: Scalars['String']
  password: Scalars['String']
}

export type RemoveBackgroundInput = {
  url: Scalars['String']
}

export type RemoveMediaInput = {
  id: Scalars['String']
}

export type RemovePremiumElementsInput = {
  draftId: Scalars['String']
  id: Scalars['String']
  slideId: Scalars['String']
}

export type RemoveSocialAccountInput = {
  accountId: Scalars['String']
  page_id?: InputMaybe<Scalars['String']>
  service: Scalars['String']
}

export type RenewSubscriptionInput = {
  coupon?: InputMaybe<Scalars['String']>
}

export type SaveTemplateInput = {
  isPublished?: Scalars['Boolean']
  keywords?: InputMaybe<Array<Scalars['String']>>
  videoId: Scalars['String']
}

export type ScheduleSubscription = {
  __typename?: 'ScheduleSubscription'
  planName?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  scheduleId?: Maybe<Scalars['String']>
}

export type SearchAnimationsInput = {
  category?: Scalars['String']
  first?: Scalars['Float']
  postStyle?: Scalars['String']
  search?: Scalars['String']
  skip?: Scalars['Float']
  sort?: Scalars['String']
}

export type SearchColorsResponse = {
  __typename?: 'SearchColorsResponse'
  brandColors?: Maybe<Array<Colors>>
  colorPalettes?: Maybe<Array<Colors>>
  colors?: Maybe<Array<Colors>>
  gradientColors?: Maybe<Array<Colors>>
  mainColor?: Maybe<Scalars['String']>
  similarColors?: Maybe<Array<Scalars['String']>>
}

export type SearchDeprecatedTemplatesInput = {
  category?: Scalars['String']
  first?: Scalars['Float']
  search?: Scalars['String']
  skip?: Scalars['Float']
  sort?: Scalars['String']
}

export type SearchTemplatesInput = {
  category?: Scalars['String']
  first?: Scalars['Float']
  search?: Scalars['String']
  skip?: Scalars['Float']
  sort?: Scalars['String']
}

export type ShapesCategoriesWithShapes = {
  __typename?: 'ShapesCategoriesWithShapes'
  ShapesData: Array<ElementsShapesData>
  _id: Scalars['String']
  createDate?: Maybe<Scalars['NewDateTimeResolver']>
  isNew?: Maybe<Scalars['Boolean']>
  key: Scalars['String']
  name: Scalars['String']
  subType: Scalars['String']
  updateDate?: Maybe<Scalars['NewDateTimeResolver']>
}

export type ShapesCategoryInput = {
  subtype?: InputMaybe<Scalars['String']>
}

export type ShapesInput = {
  category?: Scalars['String']
  filter?: Scalars['String']
  first?: Scalars['Float']
  keyword?: Scalars['String']
  skip?: Scalars['Float']
  subtype?: Scalars['String']
}

export type SocialApiResponse = {
  __typename?: 'SocialAPIResponse'
  message?: Maybe<Scalars['String']>
  post_id?: Maybe<Scalars['String']>
  status: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type SocialAccount = {
  __typename?: 'SocialAccount'
  _id: Scalars['String']
  accountId: Scalars['String']
  metaData: SocialAccountMetaData
  pageData?: Maybe<Array<SocialAccountPageData>>
  service: Scalars['String']
}

export type SocialAccountAuthTokenInput = {
  code?: InputMaybe<Scalars['String']>
  service: Scalars['String']
}

export type SocialAccountData = {
  __typename?: 'SocialAccountData'
  count: Scalars['Float']
  data: Array<SocialAccount>
}

export type SocialAccountInput = {
  service: Scalars['String']
}

export type SocialAccountLoginInput = {
  pageName?: InputMaybe<Scalars['String']>
  service: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type SocialAccountMetaData = {
  __typename?: 'SocialAccountMetaData'
  id: Scalars['String']
  name: Scalars['String']
  picture?: Maybe<Scalars['String']>
}

export type SocialAccountPageData = {
  __typename?: 'SocialAccountPageData'
  access_token?: Maybe<Scalars['String']>
  name: Scalars['String']
  page_id: Scalars['String']
}

export type Stats = {
  __typename?: 'Stats'
  _id: Scalars['String']
  bundle?: Maybe<Scalars['Float']>
  cancelled?: Maybe<Scalars['Float']>
  cancelledAmount?: Maybe<Scalars['Float']>
  createdAt: Scalars['NewDateTimeResolver']
  downgraded?: Maybe<Scalars['Float']>
  downgradedAmount?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  failedAmount?: Maybe<Scalars['Float']>
  free?: Maybe<Scalars['Float']>
  newRevenue?: Maybe<Scalars['Float']>
  pro10?: Maybe<Scalars['Float']>
  pro50?: Maybe<Scalars['Float']>
  proUnlimited?: Maybe<Scalars['Float']>
  rebill?: Maybe<Scalars['Float']>
  rebillAmount?: Maybe<Scalars['Float']>
  refund?: Maybe<Scalars['Float']>
  refundAmount?: Maybe<Scalars['Float']>
  revenue?: Maybe<Scalars['Float']>
  upgraded?: Maybe<Scalars['Float']>
  upgradedAmount?: Maybe<Scalars['Float']>
}

export type StatsInput = {
  dateRange?: InputMaybe<Scalars['String']>
}

export type StripeProduct = {
  __typename?: 'StripeProduct'
  _id: Scalars['String']
  bestValue?: Maybe<Scalars['Boolean']>
  billingPeriod: Scalars['Float']
  billingType: Scalars['String']
  credits: Scalars['Float']
  displayName: Scalars['String']
  groupId: Scalars['Float']
  mostPopular?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  price: Scalars['Float']
  /** Used in order to switch to yearly billing type. */
  priceAnnual?: Maybe<Scalars['Float']>
  priceId: Scalars['String']
  productId: Scalars['String']
  type: Scalars['String']
}

export type StripeProductGroup = {
  __typename?: 'StripeProductGroup'
  _id: Scalars['String']
  bestValue?: Maybe<Scalars['Boolean']>
  groupId: Scalars['String']
  label: Scalars['String']
  mostPopular?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  plans: Array<StripeProduct>
}

export type SubUserLoginInput = {
  subUserId: Scalars['String']
}

export type SubUserLoginResponse = {
  __typename?: 'SubUserLoginResponse'
  accessToken: Scalars['String']
  user: User
}

export type SubscriptionInput = {
  coupon?: InputMaybe<Scalars['String']>
  plan: Scalars['String']
  tokenId?: InputMaybe<Scalars['String']>
  yearly: Scalars['Boolean']
}

export type SubscriptionPreview = {
  __typename?: 'SubscriptionPreview'
  amount?: Maybe<Scalars['Float']>
  billingType?: Maybe<Scalars['String']>
  nextBillDate?: Maybe<Scalars['NewDateTimeResolver']>
  recurringPrice?: Maybe<Scalars['Float']>
  status: Scalars['String']
}

export type SubscriptionPreviewInput = {
  coupon?: InputMaybe<Scalars['String']>
  plan: Scalars['String']
  yearly: Scalars['Boolean']
}

export type SubscriptionTokenResponse = {
  __typename?: 'SubscriptionTokenResponse'
  message?: Maybe<Scalars['String']>
  paymentBehavior?: Maybe<Scalars['String']>
  planAction?: Maybe<Scalars['String']>
  status: Scalars['String']
  token: Scalars['String']
}

export type Template = {
  __typename?: 'Template'
  _id: Scalars['String']
  createDate?: Maybe<Scalars['NewDateTimeResolver']>
  featured?: Maybe<Featured>
  isFavourite?: Maybe<Scalars['Boolean']>
  isPublished?: Maybe<Scalars['Boolean']>
  keywords?: Maybe<Array<Scalars['String']>>
  updateDate?: Maybe<Scalars['NewDateTimeResolver']>
  usedCount?: Maybe<Scalars['Float']>
  userId?: Maybe<Scalars['String']>
  videoId?: Maybe<Scalars['String']>
  videoSummary?: Maybe<VideoSummary>
}

export type TemplateCategory = {
  __typename?: 'TemplateCategory'
  id: Scalars['String']
  name: Scalars['String']
}

export type TokenResponse = {
  __typename?: 'TokenResponse'
  status: Scalars['String']
  token: Scalars['String']
}

export type TrackAnimationMessageResponse = {
  __typename?: 'TrackAnimationMessageResponse'
  index?: Maybe<Scalars['String']>
  message: Scalars['String']
  status: Scalars['String']
}

export type TrackColorMessageResponse = {
  __typename?: 'TrackColorMessageResponse'
  index?: Maybe<Scalars['String']>
  message: Scalars['String']
  status: Scalars['String']
}

export type TrackElementInput = {
  id: Scalars['String']
  type: Scalars['String']
}

export type UnrenderedVideoVersion = {
  __typename?: 'UnrenderedVideoVersion'
  _id: Scalars['String']
  composition?: Maybe<Scalars['String']>
  userId: Scalars['String']
  videoId: Scalars['String']
}

export type UnrenderedVideoVersionResponse =
  | GeneralResponseWithMessage
  | UnrenderedVideoVersion

export type Unsplash = {
  __typename?: 'Unsplash'
  authorName?: Maybe<Scalars['String']>
  authorPage?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  height: Scalars['String']
  id: Scalars['String']
  preview: Scalars['String']
  url: Scalars['String']
  width: Scalars['String']
}

export type UnsplashInput = {
  category?: InputMaybe<Scalars['String']>
  keywords?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Float']>
  page?: InputMaybe<Scalars['Float']>
}

export type User = {
  __typename?: 'User'
  _id: Scalars['String']
  access?: Maybe<Access>
  activationToken?: Maybe<Scalars['String']>
  activationTokenExpires?: Maybe<Scalars['NewDateTimeResolver']>
  address?: Maybe<Address>
  amountDue?: Maybe<Scalars['Float']>
  businessName?: Maybe<Scalars['String']>
  cancelDiscount?: Maybe<Scalars['Boolean']>
  createdAt?: Maybe<Scalars['NewDateTimeResolver']>
  credits?: Maybe<CreditsData>
  creditsLimit?: Maybe<Scalars['Float']>
  email: Scalars['String']
  emailIsVerified: Scalars['Boolean']
  facebookId?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  fullName: Scalars['String']
  googleId?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['Float']>
  hasFreePlan?: Maybe<Scalars['Boolean']>
  hasPlanOutSideBilling?: Maybe<Scalars['Boolean']>
  hasProPlan?: Maybe<Scalars['Boolean']>
  hasUnlimitedPlan?: Maybe<Scalars['Boolean']>
  initials?: Maybe<Scalars['String']>
  isGhost?: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  name?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  planRemainingCredits?: Maybe<Scalars['Float']>
  remainingCredits?: Maybe<Scalars['Float']>
  resetPassword?: Maybe<Scalars['Boolean']>
  resetPasswordToken?: Maybe<Scalars['String']>
  resetPasswordTokenExpires?: Maybe<Scalars['NewDateTimeResolver']>
  role?: Maybe<Scalars['String']>
  scheduleSubscription?: Maybe<ScheduleSubscription>
  seesWatermarkedAssets?: Maybe<Scalars['Boolean']>
  specialPermission?: Maybe<Scalars['Boolean']>
  status: Scalars['String']
  stripe?: Maybe<StripeData>
  subscription: Scalars['String']
  subscriptionEndDate?: Maybe<Scalars['NewDateTimeResolver']>
  subscriptionItem: Scalars['String']
  subscriptionStatus?: Maybe<Scalars['String']>
  topupRemainingCredits?: Maybe<Scalars['Float']>
  updatedAt?: Maybe<Scalars['NewDateTimeResolver']>
  userNeedsUpgrade?: Maybe<Scalars['Boolean']>
  vatNumber?: Maybe<Scalars['String']>
  webhookStatus?: Maybe<Scalars['String']>
  yearly?: Maybe<Scalars['Boolean']>
}

export type UserCheckInput = {
  viewUserId?: InputMaybe<Scalars['String']>
}

export type UserDataChangeInput = {
  access?: InputMaybe<Scalars['String']>
  credits?: Scalars['Float']
  email?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  oldPassword?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  passwordRepeat?: InputMaybe<Scalars['String']>
  plan?: Scalars['String']
  role?: InputMaybe<Scalars['String']>
  specialPermission?: InputMaybe<Scalars['Boolean']>
  viewUserId: Scalars['String']
}

export type UserDataDeleteInput = {
  viewUserId: Scalars['String']
}

export type UserDataInput = {
  email?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  oldPassword?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  passwordRepeat?: InputMaybe<Scalars['String']>
}

export type UserFilterInput = {
  first?: Scalars['Float']
  lastLogin?: InputMaybe<Scalars['String']>
  plan?: InputMaybe<Scalars['String']>
  searchWord?: InputMaybe<Scalars['String']>
  skip?: Scalars['Float']
  status?: InputMaybe<Scalars['String']>
  video?: InputMaybe<Scalars['String']>
}

export type UserFiltered = {
  __typename?: 'UserFiltered'
  _id?: Maybe<Scalars['String']>
  amountDue?: Maybe<Scalars['Float']>
  createdAt?: Maybe<Scalars['NewDateTimeResolver']>
  email?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  plan?: Maybe<Scalars['String']>
  planData?: Maybe<Array<PlanData>>
  value?: Maybe<Scalars['String']>
  videos?: Maybe<Scalars['String']>
}

export type UsersFilteredCount = {
  __typename?: 'UsersFilteredCount'
  length: Scalars['String']
}

export type ValidateInput = {
  token: Scalars['String']
}

export type VerifyInput = {
  email: Scalars['String']
  gRecaptchaToken: Scalars['String']
  gRecaptchaType: Scalars['String']
}

export type VideoSummary = {
  __typename?: 'VideoSummary'
  duration?: Maybe<Scalars['Float']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  videoSize?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
  videoVersionId?: Maybe<Scalars['String']>
}

export type VideoVersion = {
  __typename?: 'VideoVersion'
  _id: Scalars['String']
  composition?: Maybe<Scalars['String']>
  isPaid?: Maybe<Scalars['Boolean']>
  isValid?: Maybe<Scalars['Boolean']>
  progress?: Maybe<Scalars['Float']>
  renderStatus?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  userId: Scalars['String']
  videoFormat?: Maybe<Scalars['String']>
  videoGridPoster?: Maybe<Scalars['String']>
  videoId: Scalars['String']
  videoListPoster?: Maybe<Scalars['String']>
  videoTitle: Scalars['String']
}

export type VideoVersionInput = {
  versionId: Scalars['String']
}

export type VideoVersionResponse = GeneralResponseWithMessage | VideoVersion

export type VideoVersionsInput = {
  videoId?: InputMaybe<Scalars['String']>
}

export type AddRecentsInput = {
  assetId?: InputMaybe<Scalars['String']>
  author?: InputMaybe<Scalars['String']>
  authorPage?: InputMaybe<Scalars['String']>
  bpm?: InputMaybe<Scalars['Float']>
  content: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['PositiveFloat']>
  height?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['String']>
  mediaType?: InputMaybe<Scalars['String']>
  ratio?: InputMaybe<Scalars['String']>
  source: Scalars['String']
  thumbnail?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  width?: InputMaybe<Scalars['Float']>
}

export type CreditsData = {
  __typename?: 'creditsData'
  maxCredits?: Maybe<Scalars['Float']>
  topupCredits?: Maybe<Scalars['Float']>
  usedCredits?: Maybe<Scalars['Float']>
  usedTopup?: Maybe<Scalars['Float']>
}

export type FavUploaded = {
  __typename?: 'favUploaded'
  duration?: Maybe<Scalars['Float']>
  fileName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export type PostSocialMediaInput = {
  accountId: Scalars['String']
  content?: InputMaybe<Scalars['String']>
  maction?: InputMaybe<Scalars['String']>
  page_id?: InputMaybe<Scalars['String']>
  service: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  uploadId?: InputMaybe<Scalars['String']>
  video_url: Scalars['String']
}

export type RemoveRecentsInput = {
  assetId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  mediaType?: InputMaybe<Scalars['String']>
  source: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type StripeData = {
  __typename?: 'stripeData'
  customerId?: Maybe<Scalars['String']>
  last4?: Maybe<Scalars['String']>
  paymentMethodId?: Maybe<Scalars['String']>
  taxId?: Maybe<Scalars['String']>
}

export type TrackAnimationUsedCountMutationVariables = Exact<{
  input: AnimationTrackInput
}>

export type TrackAnimationUsedCountMutation = {
  __typename?: 'Mutation'
  trackAnimationUsedCount: {
    __typename?: 'TrackAnimationMessageResponse'
    message: string
    index?: string
    status: string
  }
}

export type SearchAnimationsQueryVariables = Exact<{
  search: SearchAnimationsInput
}>

export type SearchAnimationsQuery = {
  __typename?: 'Query'
  searchAnimations: Array<{
    __typename?: 'Animation'
    _id: string
    name: string
    thumbnail: string
    webm: string
    duration?: number
    width?: number
    height?: number
  }>
}

export type SaveBrandColorMutationVariables = Exact<{
  input: AddBrandColorInput
}>

export type SaveBrandColorMutation = {
  __typename?: 'Mutation'
  saveBrandColor: {
    __typename?: 'BrandColorMessageResponse'
    message: string
    index?: string
  }
}

export type RemoveBrandColorMutationVariables = Exact<{
  input: AddBrandColorInput
}>

export type RemoveBrandColorMutation = {
  __typename?: 'Mutation'
  removeBrandColor: {
    __typename?: 'BrandColorMessageResponse'
    message: string
    index?: string
  }
}

export type TrackColorUsedCountMutationVariables = Exact<{
  input: AddBrandColorInput
}>

export type TrackColorUsedCountMutation = {
  __typename?: 'Mutation'
  trackColorUsedCount: {
    __typename?: 'TrackColorMessageResponse'
    message: string
    index?: string
    status: string
  }
}

export type SearchColorsQueryVariables = Exact<{
  search: ColorsInput
}>

export type SearchColorsQuery = {
  __typename?: 'Query'
  data: {
    __typename?: 'SearchColorsResponse'
    mainColor?: string
    similarColors?: Array<string>
    colors?: Array<{ __typename?: 'Colors'; _id: string; color: string }>
    brandColors?: Array<{ __typename?: 'Colors'; _id: string; color: string }>
    colorPalettes?: Array<{
      __typename?: 'Colors'
      _id: string
      color: string
      palettes?: Array<string>
    }>
    gradientColors?: Array<{
      __typename?: 'Colors'
      _id: string
      color: string
      palettes?: Array<string>
    }>
  }
}

export type GetColorsQueryVariables = Exact<{ [key: string]: never }>

export type GetColorsQuery = {
  __typename?: 'Query'
  data: Array<{ __typename?: 'Colors'; _id: string; color: string }>
}

export type GetColorsPalettesQueryVariables = Exact<{ [key: string]: never }>

export type GetColorsPalettesQuery = {
  __typename?: 'Query'
  data: Array<{
    __typename?: 'Colors'
    _id: string
    color: string
    palettes?: Array<string>
  }>
}

export type GetGradientColorsQueryVariables = Exact<{ [key: string]: never }>

export type GetGradientColorsQuery = {
  __typename?: 'Query'
  data: Array<{
    __typename?: 'Colors'
    _id: string
    color: string
    palettes?: Array<string>
  }>
}

export type GetBrandColorsQueryVariables = Exact<{
  search: ColorsInput
}>

export type GetBrandColorsQuery = {
  __typename?: 'Query'
  data: Array<{ __typename?: 'Colors'; _id: string; color: string }>
}

export type VideoCreditPaymentIntentMutationVariables = Exact<{
  input: PaymentIntent
}>

export type VideoCreditPaymentIntentMutation = {
  __typename?: 'Mutation'
  videoCreditPaymentIntent: {
    __typename?: 'TokenResponse'
    token: string
    status: string
  }
}

export type CreditsListQueryVariables = Exact<{ [key: string]: never }>

export type CreditsListQuery = {
  __typename?: 'Query'
  creditsList: Array<{
    __typename?: 'Credits'
    id: string
    price: string
    credits: string
    name: string
  }>
}

export type SaveDraftMutationVariables = Exact<{
  input: DraftAddInput
}>

export type SaveDraftMutation = {
  __typename?: 'Mutation'
  saveDraft: { __typename?: 'Drafts'; _id: string; title?: string }
}

export type RemovePremiumElementMutationVariables = Exact<{
  input: RemovePremiumElementsInput
}>

export type RemovePremiumElementMutation = {
  __typename?: 'Mutation'
  removePremiumElement: {
    __typename?: 'DraftsMessageResponse'
    message?: string
    status: string
  }
}

export type CreateDraftMutationVariables = Exact<{
  input: CreateDraftInput
}>

export type CreateDraftMutation = {
  __typename?: 'Mutation'
  createDraft: { __typename?: 'Drafts'; id: string }
}

export type GetDraftsQueryVariables = Exact<{
  input?: DraftsGetInput
}>

export type GetDraftsQuery = {
  __typename?: 'Query'
  getDrafts: Array<{
    __typename?: 'Drafts'
    draft?: string
    title?: string
    duration?: number
    videoSize: string
    layout: number
    createDate?: any
    updateDate?: any
  }>
}

export type ReadPremiumElementsQueryVariables = Exact<{
  input: PremiumElementsInput
}>

export type ReadPremiumElementsQuery = {
  __typename?: 'Query'
  readPremiumElements: Array<{
    __typename?: 'PremiumElement'
    id: string
    title: string
    type: string
    thumbImage: string
    slideId: string
  }>
}

export type TrackElementUsedCountMutationVariables = Exact<{
  input: TrackElementInput
}>

export type TrackElementUsedCountMutation = {
  __typename?: 'Mutation'
  trackElementUsedCount: {
    __typename?: 'GeneralResponseWithMessage'
    message: string
    status?: string
  }
}

export type GetElementsObjectsCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetElementsObjectsCategoriesQuery = {
  __typename?: 'Query'
  objectsCategories: Array<{
    __typename?: 'ObjectsCategories'
    _id: string
    name: string
    key: string
  }>
}

export type GetElementsObjectsCategoriesWithObjectsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetElementsObjectsCategoriesWithObjectsQuery = {
  __typename?: 'Query'
  objectsCategories: Array<{
    __typename?: 'ObjectsCategoriesWithObjects'
    _id: string
    name: string
    key: string
    isNew?: boolean
    ObjectsData: Array<{
      __typename?: 'ElementsObjectsData'
      _id: string
      name: string
      thumbUrl: string
      mainUrl: string
      width: string
      height: string
    }>
  }>
}

export type GetElementsShapesCategoriesWithShapesQueryVariables = Exact<{
  search?: ShapesCategoryInput
}>

export type GetElementsShapesCategoriesWithShapesQuery = {
  __typename?: 'Query'
  shapesCategories: Array<{
    __typename?: 'ShapesCategoriesWithShapes'
    _id: string
    name: string
    key: string
    isNew?: boolean
    ShapesData: Array<{
      __typename?: 'ElementsShapesData'
      _id: string
      name: string
      thumbUrl: string
      mainUrl: string
      width: string
      height: string
      svg?: string
    }>
  }>
}

export type GetElementsShapesQueryVariables = Exact<{
  search?: ShapesInput
}>

export type GetElementsShapesQuery = {
  __typename?: 'Query'
  shapes: Array<{
    __typename?: 'ElementsShapesData'
    _id: string
    name: string
    thumbUrl: string
    mainUrl: string
    width: string
    height: string
    svg?: string
  }>
}

export type GetElementsObjectsQueryVariables = Exact<{
  search?: ObjectsInput
}>

export type GetElementsObjectsQuery = {
  __typename?: 'Query'
  objects: Array<{
    __typename?: 'ElementsObjectsData'
    _id: string
    name: string
    thumbUrl: string
    mainUrl: string
    width: string
    height: string
  }>
}

export type AddFavouriteMusicMutationVariables = Exact<{
  data: FavouriteMusicAddInput
}>

export type AddFavouriteMusicMutation = {
  __typename?: 'Mutation'
  addFavouriteMusic: {
    __typename?: 'GeneralResponseWithMessage'
    status?: string
    message: string
  }
}

export type RemoveFavouriteMusicMutationVariables = Exact<{
  data: FavouriteMusicRemoveInput
}>

export type RemoveFavouriteMusicMutation = {
  __typename?: 'Mutation'
  removeFavouriteMusic: {
    __typename?: 'GeneralResponseWithMessage'
    status?: string
    message: string
  }
}

export type GetFavouriteMusicListQueryVariables = Exact<{
  input: FavouriteMusicInput
}>

export type GetFavouriteMusicListQuery = {
  __typename?: 'Query'
  filterFavoriteMusic: Array<{
    __typename?: 'FavouriteMusic'
    _id: string
    userId: string
    type: string
    referenceId: string
    melodie?: {
      __typename?: 'MelodieObject'
      id?: string
      title?: string
      url?: string
      bpm?: number
      duration?: number
    }
    uploaded?: Array<{
      __typename?: 'favUploaded'
      name?: string
      fileName?: string
      url?: string
      duration?: number
    }>
  }>
}

export type ChangeFontDataMutationVariables = Exact<{
  input: FontUseInput
}>

export type ChangeFontDataMutation = {
  __typename?: 'Mutation'
  changeFontData: {
    __typename?: 'FontsMessageResponse'
    message: string
    status: string
    type: string
  }
}

export type SearchFontsQueryVariables = Exact<{
  search: FontsInput
}>

export type SearchFontsQuery = {
  __typename?: 'Query'
  searchFonts: Array<{
    __typename?: 'Fonts'
    _id: string
    family?: string
    fontsUrl?: string
    imageUrl?: string
    imageExampleUrl?: string
    svg?: string
    source?: string
    isPremium?: boolean
  }>
}

export type GetGiphyAssetsQueryVariables = Exact<{
  search?: GiphyFilterInput
}>

export type GetGiphyAssetsQuery = {
  __typename?: 'Query'
  giphyAssets: Array<{
    __typename?: 'Giphy'
    id: string
    url: string
    width: string
    height: string
  }>
}

export type AddMediaMutationVariables = Exact<{
  input: AddMediaInput
}>

export type AddMediaMutation = {
  __typename?: 'Mutation'
  addMedia: {
    __typename?: 'AddMediaResponse'
    uploadStatus: string
    message?: string
    name?: string
    id?: string
    presignedUrl?: string
  }
}

export type RemoveMediaMutationVariables = Exact<{
  input: RemoveMediaInput
}>

export type RemoveMediaMutation = {
  __typename?: 'Mutation'
  removeMedia: { __typename?: 'ListedMedia'; _id: string; uniqueId?: string }
}

export type ListMediaQueryVariables = Exact<{
  data: ListMediaInput
}>

export type ListMediaQuery = {
  __typename?: 'Query'
  listMedia: Array<{
    __typename?: 'ListedMedia'
    _id: string
    url?: string
    name?: string
    userId?: string
    width?: any
    height?: any
    ratio?: string
    favourite?: boolean
    duration?: any
    fileName?: string
    uniqueId?: string
    uploadStatus?: string
    progress?: number
    mediaType: string
    thumbnail?: string
  }>
}

export type GetMelodieAudiosQueryVariables = Exact<{
  search?: MelodieAudioInput
}>

export type GetMelodieAudiosQuery = {
  __typename?: 'Query'
  melodieAudios: Array<{
    __typename?: 'MelodieAudio'
    id: string
    title: string
    description: string
    url: string
    bpm: number
    duration: number
    waveformUrl: string
    favourite: boolean
  }>
}

export type GetPeacockVideosCategoriesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPeacockVideosCategoriesQuery = {
  __typename?: 'Query'
  categories: Array<{
    __typename?: 'PeacockVideosCategories'
    _id: string
    name: string
    key: string
  }>
}

export type GetPeacockVideosQueryVariables = Exact<{
  search?: PeacockVideosInput
}>

export type GetPeacockVideosQuery = {
  __typename?: 'Query'
  videos: Array<{
    __typename?: 'PeacockVideos'
    _id: string
    gifingId: string
    title: string
    mp4url: string
    watermarkmp4: string
    thumbnail: string
    duration: number
    hasAudio?: boolean
  }>
}

export type GetSinglePeacockVideoQueryVariables = Exact<{
  search: PeacockVideoInput
}>

export type GetSinglePeacockVideoQuery = {
  __typename?: 'Query'
  video: {
    __typename?: 'PeacockVideos'
    _id: string
    gifingId: string
    title: string
    keywords?: string
    mp4url: string
    watermarkmp4: string
    thumbnail: string
    duration: number
    hasAudio?: boolean
  }
}

export type GetPexelsImagesQueryVariables = Exact<{
  search?: PexelsImageInput
}>

export type GetPexelsImagesQuery = {
  __typename?: 'Query'
  pexelsImages: Array<{
    __typename?: 'PexelsImage'
    id: string
    url: string
    preview_url: string
    description: string
    width: string
    height: string
  }>
}

export type GetPexelsVideosQueryVariables = Exact<{
  search?: PexelsVideoInput
}>

export type GetPexelsVideosQuery = {
  __typename?: 'Query'
  pexelsVideos: Array<{
    __typename?: 'PexelsVideo'
    id: string
    description: string
    hd_url: string
    sd_url: string
    thumbnail: string
    duration?: any
    width?: any
    height?: any
    aspect_ratio: string
    collection?: string
  }>
}

export type AddRecentMutationVariables = Exact<{
  input: AddRecentsInput
}>

export type AddRecentMutation = {
  __typename?: 'Mutation'
  addRecent: { __typename?: 'Recents'; assetId?: string }
}

export type RemoveRecentMutationVariables = Exact<{
  input: RemoveRecentsInput
}>

export type RemoveRecentMutation = {
  __typename?: 'Mutation'
  removeRecent: { __typename?: 'Recents'; assetId?: string }
}

export type RecentsListQueryVariables = Exact<{
  data: RecentsInput
}>

export type RecentsListQuery = {
  __typename?: 'Query'
  recentsList: Array<{
    __typename?: 'Recents'
    assetId?: string
    title?: string
    author?: string
    authorPage?: string
    content: string
    description?: string
    duration?: any
    thumbnail?: string
    bpm?: number
    ratio?: string
    width?: number
    height?: number
    type?: string
    source: string
    favourite?: boolean
  }>
}

export type RemoveBackgroundMutationVariables = Exact<{
  in: RemoveBackgroundInput
}>

export type RemoveBackgroundMutation = { __typename?: 'Mutation'; data: string }

export type PostVideoOnsocialPlatformMutationVariables = Exact<{
  input: PostSocialMediaInput
}>

export type PostVideoOnsocialPlatformMutation = {
  __typename?: 'Mutation'
  postVideoOnSocial: {
    __typename?: 'SocialAPIResponse'
    status: string
    message?: string
    url?: string
    post_id?: string
  }
}

export type GetAuthLoginUrlQueryVariables = Exact<{
  search: SocialAccountLoginInput
}>

export type GetAuthLoginUrlQuery = {
  __typename?: 'Query'
  getAuthLoginURL: {
    __typename?: 'SocialAPIResponse'
    status: string
    url?: string
    message?: string
  }
}

export type GetAllSocialAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllSocialAccountsQuery = {
  __typename?: 'Query'
  getSocialAccounts: {
    __typename?: 'GetSocialAccountResponse'
    facebook: {
      __typename?: 'SocialAccountData'
      data: Array<{
        __typename?: 'SocialAccount'
        accountId: string
        metaData: {
          __typename?: 'SocialAccountMetaData'
          id: string
          name: string
        }
        pageData?: Array<{
          __typename?: 'SocialAccountPageData'
          page_id: string
          name: string
        }>
      }>
    }
    instagram: {
      __typename?: 'SocialAccountData'
      data: Array<{
        __typename?: 'SocialAccount'
        accountId: string
        metaData: {
          __typename?: 'SocialAccountMetaData'
          id: string
          name: string
        }
        pageData?: Array<{
          __typename?: 'SocialAccountPageData'
          page_id: string
          name: string
        }>
      }>
    }
    tiktok: {
      __typename?: 'SocialAccountData'
      data: Array<{
        __typename?: 'SocialAccount'
        accountId: string
        metaData: {
          __typename?: 'SocialAccountMetaData'
          id: string
          name: string
        }
        pageData?: Array<{
          __typename?: 'SocialAccountPageData'
          page_id: string
          name: string
        }>
      }>
    }
    youtube: {
      __typename?: 'SocialAccountData'
      data: Array<{
        __typename?: 'SocialAccount'
        accountId: string
        metaData: {
          __typename?: 'SocialAccountMetaData'
          id: string
          name: string
        }
        pageData?: Array<{
          __typename?: 'SocialAccountPageData'
          page_id: string
          name: string
        }>
      }>
    }
  }
}

export type GetAllSocialConnectedAccountsCountQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAllSocialConnectedAccountsCountQuery = {
  __typename?: 'Query'
  getSocialAccounts: {
    __typename?: 'GetSocialAccountResponse'
    facebook: { __typename?: 'SocialAccountData'; count: number }
    instagram: { __typename?: 'SocialAccountData'; count: number }
    tiktok: { __typename?: 'SocialAccountData'; count: number }
    youtube: { __typename?: 'SocialAccountData'; count: number }
  }
}

export type GetSocialAccountQueryVariables = Exact<{
  search: SocialAccountInput
}>

export type GetSocialAccountQuery = {
  __typename?: 'Query'
  getSocialAccount: Array<{
    __typename?: 'SocialAccount'
    accountId: string
    service: string
    metaData: {
      __typename?: 'SocialAccountMetaData'
      id: string
      name: string
      picture?: string
    }
    pageData?: Array<{
      __typename?: 'SocialAccountPageData'
      page_id: string
      name: string
    }>
  }>
}

export type SearchTemplatesQueryVariables = Exact<{
  search: SearchTemplatesInput
}>

export type SearchTemplatesQuery = {
  __typename?: 'Query'
  searchTemplates: Array<{
    __typename?: 'Template'
    _id: string
    isFavourite?: boolean
    videoSummary?: {
      __typename?: 'VideoSummary'
      title?: string
      videoUrl?: string
      thumbnail?: string
      duration?: number
      videoSize?: string
    }
  }>
}

export type SaveTemplateMutationVariables = Exact<{
  input: SaveTemplateInput
}>

export type SaveTemplateMutation = {
  __typename?: 'Mutation'
  saveTemplate: { __typename?: 'Template'; _id: string; isPublished?: boolean }
}

export type GetTemplateQueryVariables = Exact<{
  input: GetTemplateInput
}>

export type GetTemplateQuery = {
  __typename?: 'Query'
  getTemplate?: {
    __typename?: 'Template'
    _id: string
    videoId?: string
    keywords?: Array<string>
    isPublished?: boolean
  }
}

export type SearchUnsplashImageQueryVariables = Exact<{
  search: UnsplashInput
}>

export type SearchUnsplashImageQuery = {
  __typename?: 'Query'
  unsplashImages: Array<{
    __typename?: 'Unsplash'
    id: string
    description?: string
    authorName?: string
    authorPage?: string
    preview: string
    url: string
    width: string
    height: string
  }>
}

export type SubscriptionIntentMutationVariables = Exact<{
  subscriptionIntent: SubscriptionInput
}>

export type SubscriptionIntentMutation = {
  __typename?: 'Mutation'
  subscriptionIntent: {
    __typename?: 'SubscriptionTokenResponse'
    status: string
    token: string
    message?: string
    planAction?: string
    paymentBehavior?: string
  }
}

export type CancelIncompleteSubscriptionMutationVariables = Exact<{
  [key: string]: never
}>

export type CancelIncompleteSubscriptionMutation = {
  __typename?: 'Mutation'
  cancelIncompleteSubscription: {
    __typename?: 'GeneralResponseWithMessage'
    status?: string
    message: string
  }
}

export type ConsumeCreditMutationVariables = Exact<{
  consumeCreditInput: ConsumeCreditInput
}>

export type ConsumeCreditMutation = {
  __typename?: 'Mutation'
  consumeCredit: {
    __typename?: 'GeneralResponseWithMessage'
    status?: string
    message: string
  }
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'User'
    _id: string
    email: string
    name?: string
    initials?: string
    lastName: string
    password?: string
    picture?: string
    role?: string
    plan?: string
    yearly?: boolean
    subscriptionEndDate?: any
    amountDue?: number
    businessName?: string
    groupId?: number
    subscriptionStatus?: string
    webhookStatus?: string
    isGhost?: boolean
    seesWatermarkedAssets?: boolean
    hasFreePlan?: boolean
    hasProPlan?: boolean
    hasUnlimitedPlan?: boolean
    remainingCredits?: number
    hasPlanOutSideBilling?: boolean
    creditsLimit?: number
    topupRemainingCredits?: number
    planRemainingCredits?: number
    userNeedsUpgrade?: boolean
    stripe?: {
      __typename?: 'stripeData'
      customerId?: string
      paymentMethodId?: string
      last4?: string
    }
    credits?: {
      __typename?: 'creditsData'
      usedCredits?: number
      maxCredits?: number
      topupCredits?: number
      usedTopup?: number
    }
    address?: {
      __typename?: 'Address'
      city?: string
      line1?: string
      line2?: string
      zipcode?: string
      country?: string
    }
    scheduleSubscription?: {
      __typename?: 'ScheduleSubscription'
      scheduleId?: string
      planName?: string
      price?: number
    }
  }
}

export type CheckCouponQueryVariables = Exact<{
  coupon: CouponInput
}>

export type CheckCouponQuery = {
  __typename?: 'Query'
  checkCoupon: {
    __typename?: 'CouponResponse'
    durationInMonth?: string
    percentOff?: number
    status: string
    products?: Array<string>
  }
}

export type GetStripeGroupPlansQueryVariables = Exact<{ [key: string]: never }>

export type GetStripeGroupPlansQuery = {
  __typename?: 'Query'
  getStripeGroupPlans: Array<{
    __typename?: 'StripeProductGroup'
    _id: string
    name: string
    label: string
    groupId: string
    mostPopular?: boolean
    bestValue?: boolean
    plans: Array<{
      __typename?: 'StripeProduct'
      _id: string
      name: string
      price: number
      billingType: string
      productId: string
    }>
  }>
}

export type GetStripeTopupPlansQueryVariables = Exact<{ [key: string]: never }>

export type GetStripeTopupPlansQuery = {
  __typename?: 'Query'
  getStripeTopupPlans: Array<{
    __typename?: 'StripeProduct'
    _id: string
    name: string
    displayName: string
    productId: string
    priceId: string
    billingType: string
    billingPeriod: number
    credits: number
    price: number
    type: string
  }>
}

export type GetSubscriptionPreviewQueryVariables = Exact<{
  input: SubscriptionPreviewInput
}>

export type GetSubscriptionPreviewQuery = {
  __typename?: 'Query'
  getSubscriptionPreview: {
    __typename?: 'SubscriptionPreview'
    status: string
    amount?: number
    recurringPrice?: number
    billingType?: string
    nextBillDate?: any
  }
}

export type CreateVideoVersionMutationVariables = Exact<{
  input: CreateVideoVersionInput
}>

export type CreateVideoVersionMutation = {
  __typename?: 'Mutation'
  createVideoVersion:
    | {
        __typename?: 'GeneralResponseWithMessage'
        message: string
        success?: boolean
        status?: string
      }
    | {
        __typename?: 'VideoVersion'
        _id: string
        userId: string
        videoId: string
        videoTitle: string
        videoFormat?: string
        videoListPoster?: string
        videoGridPoster?: string
        progress?: number
        url?: string
        renderStatus?: string
        composition?: string
        isPaid?: boolean
      }
}

export type GetVideoVersionsQueryVariables = Exact<{
  input?: InputMaybe<VideoVersionsInput>
}>

export type GetVideoVersionsQuery = {
  __typename?: 'Query'
  getVideoVersions: Array<{
    __typename?: 'VideoVersion'
    _id: string
    userId: string
    videoId: string
    videoTitle: string
    videoFormat?: string
    videoListPoster?: string
    videoGridPoster?: string
    progress?: number
    url?: string
    renderStatus?: string
    composition?: string
    isPaid?: boolean
    isValid?: boolean
  }>
}

export type GetVideoVersionByIdQueryVariables = Exact<{
  input: VideoVersionInput
}>

export type GetVideoVersionByIdQuery = {
  __typename?: 'Query'
  getVideoVersionById:
    | {
        __typename: 'GeneralResponseWithMessage'
        message: string
        success?: boolean
      }
    | {
        __typename: 'VideoVersion'
        _id: string
        userId: string
        videoId: string
        videoTitle: string
        videoListPoster?: string
        videoGridPoster?: string
        url?: string
        isPaid?: boolean
        isValid?: boolean
      }
}

export const TrackAnimationUsedCountDocument = gql`
  mutation trackAnimationUsedCount($input: AnimationTrackInput!) {
    trackAnimationUsedCount(input: $input) {
      message
      index
      status
    }
  }
`
export type TrackAnimationUsedCountMutationFn = Apollo.MutationFunction<
  TrackAnimationUsedCountMutation,
  TrackAnimationUsedCountMutationVariables
>

/**
 * __useTrackAnimationUsedCountMutation__
 *
 * To run a mutation, you first call `useTrackAnimationUsedCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackAnimationUsedCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackAnimationUsedCountMutation, { data, loading, error }] = useTrackAnimationUsedCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackAnimationUsedCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackAnimationUsedCountMutation,
    TrackAnimationUsedCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TrackAnimationUsedCountMutation,
    TrackAnimationUsedCountMutationVariables
  >(TrackAnimationUsedCountDocument, options)
}
export type TrackAnimationUsedCountMutationHookResult = ReturnType<
  typeof useTrackAnimationUsedCountMutation
>
export type TrackAnimationUsedCountMutationResult =
  Apollo.MutationResult<TrackAnimationUsedCountMutation>
export type TrackAnimationUsedCountMutationOptions = Apollo.BaseMutationOptions<
  TrackAnimationUsedCountMutation,
  TrackAnimationUsedCountMutationVariables
>
export const SearchAnimationsDocument = gql`
  query SearchAnimations($search: SearchAnimationsInput!) {
    searchAnimations(input: $search) {
      _id
      name
      thumbnail
      webm
      duration
      width
      height
    }
  }
`

/**
 * __useSearchAnimationsQuery__
 *
 * To run a query within a React component, call `useSearchAnimationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnimationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnimationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchAnimationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchAnimationsQuery,
    SearchAnimationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchAnimationsQuery, SearchAnimationsQueryVariables>(
    SearchAnimationsDocument,
    options,
  )
}
export function useSearchAnimationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAnimationsQuery,
    SearchAnimationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchAnimationsQuery,
    SearchAnimationsQueryVariables
  >(SearchAnimationsDocument, options)
}
export type SearchAnimationsQueryHookResult = ReturnType<
  typeof useSearchAnimationsQuery
>
export type SearchAnimationsLazyQueryHookResult = ReturnType<
  typeof useSearchAnimationsLazyQuery
>
export type SearchAnimationsQueryResult = Apollo.QueryResult<
  SearchAnimationsQuery,
  SearchAnimationsQueryVariables
>
export const SaveBrandColorDocument = gql`
  mutation saveBrandColor($input: AddBrandColorInput!) {
    saveBrandColor(input: $input) {
      message
      index
    }
  }
`
export type SaveBrandColorMutationFn = Apollo.MutationFunction<
  SaveBrandColorMutation,
  SaveBrandColorMutationVariables
>

/**
 * __useSaveBrandColorMutation__
 *
 * To run a mutation, you first call `useSaveBrandColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBrandColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBrandColorMutation, { data, loading, error }] = useSaveBrandColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBrandColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveBrandColorMutation,
    SaveBrandColorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveBrandColorMutation,
    SaveBrandColorMutationVariables
  >(SaveBrandColorDocument, options)
}
export type SaveBrandColorMutationHookResult = ReturnType<
  typeof useSaveBrandColorMutation
>
export type SaveBrandColorMutationResult =
  Apollo.MutationResult<SaveBrandColorMutation>
export type SaveBrandColorMutationOptions = Apollo.BaseMutationOptions<
  SaveBrandColorMutation,
  SaveBrandColorMutationVariables
>
export const RemoveBrandColorDocument = gql`
  mutation removeBrandColor($input: AddBrandColorInput!) {
    removeBrandColor(input: $input) {
      message
      index
    }
  }
`
export type RemoveBrandColorMutationFn = Apollo.MutationFunction<
  RemoveBrandColorMutation,
  RemoveBrandColorMutationVariables
>

/**
 * __useRemoveBrandColorMutation__
 *
 * To run a mutation, you first call `useRemoveBrandColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBrandColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBrandColorMutation, { data, loading, error }] = useRemoveBrandColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveBrandColorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBrandColorMutation,
    RemoveBrandColorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveBrandColorMutation,
    RemoveBrandColorMutationVariables
  >(RemoveBrandColorDocument, options)
}
export type RemoveBrandColorMutationHookResult = ReturnType<
  typeof useRemoveBrandColorMutation
>
export type RemoveBrandColorMutationResult =
  Apollo.MutationResult<RemoveBrandColorMutation>
export type RemoveBrandColorMutationOptions = Apollo.BaseMutationOptions<
  RemoveBrandColorMutation,
  RemoveBrandColorMutationVariables
>
export const TrackColorUsedCountDocument = gql`
  mutation trackColorUsedCount($input: AddBrandColorInput!) {
    trackColorUsedCount(input: $input) {
      message
      index
      status
    }
  }
`
export type TrackColorUsedCountMutationFn = Apollo.MutationFunction<
  TrackColorUsedCountMutation,
  TrackColorUsedCountMutationVariables
>

/**
 * __useTrackColorUsedCountMutation__
 *
 * To run a mutation, you first call `useTrackColorUsedCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackColorUsedCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackColorUsedCountMutation, { data, loading, error }] = useTrackColorUsedCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackColorUsedCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackColorUsedCountMutation,
    TrackColorUsedCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TrackColorUsedCountMutation,
    TrackColorUsedCountMutationVariables
  >(TrackColorUsedCountDocument, options)
}
export type TrackColorUsedCountMutationHookResult = ReturnType<
  typeof useTrackColorUsedCountMutation
>
export type TrackColorUsedCountMutationResult =
  Apollo.MutationResult<TrackColorUsedCountMutation>
export type TrackColorUsedCountMutationOptions = Apollo.BaseMutationOptions<
  TrackColorUsedCountMutation,
  TrackColorUsedCountMutationVariables
>
export const SearchColorsDocument = gql`
  query searchColors($search: ColorsInput!) {
    data: searchColors(input: $search) {
      mainColor
      similarColors
      colors {
        _id
        color
      }
      brandColors {
        _id
        color
      }
      colorPalettes {
        _id
        color
        palettes
      }
      gradientColors {
        _id
        color
        palettes
      }
    }
  }
`

/**
 * __useSearchColorsQuery__
 *
 * To run a query within a React component, call `useSearchColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchColorsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchColorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchColorsQuery,
    SearchColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchColorsQuery, SearchColorsQueryVariables>(
    SearchColorsDocument,
    options,
  )
}
export function useSearchColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchColorsQuery,
    SearchColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchColorsQuery, SearchColorsQueryVariables>(
    SearchColorsDocument,
    options,
  )
}
export type SearchColorsQueryHookResult = ReturnType<
  typeof useSearchColorsQuery
>
export type SearchColorsLazyQueryHookResult = ReturnType<
  typeof useSearchColorsLazyQuery
>
export type SearchColorsQueryResult = Apollo.QueryResult<
  SearchColorsQuery,
  SearchColorsQueryVariables
>
export const GetColorsDocument = gql`
  query getColors {
    data: getColors {
      _id
      color
    }
  }
`

/**
 * __useGetColorsQuery__
 *
 * To run a query within a React component, call `useGetColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetColorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetColorsQuery,
    GetColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetColorsQuery, GetColorsQueryVariables>(
    GetColorsDocument,
    options,
  )
}
export function useGetColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetColorsQuery,
    GetColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetColorsQuery, GetColorsQueryVariables>(
    GetColorsDocument,
    options,
  )
}
export type GetColorsQueryHookResult = ReturnType<typeof useGetColorsQuery>
export type GetColorsLazyQueryHookResult = ReturnType<
  typeof useGetColorsLazyQuery
>
export type GetColorsQueryResult = Apollo.QueryResult<
  GetColorsQuery,
  GetColorsQueryVariables
>
export const GetColorsPalettesDocument = gql`
  query getColorsPalettes {
    data: getColorsPalettes {
      _id
      color
      palettes
    }
  }
`

/**
 * __useGetColorsPalettesQuery__
 *
 * To run a query within a React component, call `useGetColorsPalettesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetColorsPalettesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetColorsPalettesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetColorsPalettesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetColorsPalettesQuery,
    GetColorsPalettesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetColorsPalettesQuery,
    GetColorsPalettesQueryVariables
  >(GetColorsPalettesDocument, options)
}
export function useGetColorsPalettesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetColorsPalettesQuery,
    GetColorsPalettesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetColorsPalettesQuery,
    GetColorsPalettesQueryVariables
  >(GetColorsPalettesDocument, options)
}
export type GetColorsPalettesQueryHookResult = ReturnType<
  typeof useGetColorsPalettesQuery
>
export type GetColorsPalettesLazyQueryHookResult = ReturnType<
  typeof useGetColorsPalettesLazyQuery
>
export type GetColorsPalettesQueryResult = Apollo.QueryResult<
  GetColorsPalettesQuery,
  GetColorsPalettesQueryVariables
>
export const GetGradientColorsDocument = gql`
  query getGradientColors {
    data: getGradientColors {
      _id
      color
      palettes
    }
  }
`

/**
 * __useGetGradientColorsQuery__
 *
 * To run a query within a React component, call `useGetGradientColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradientColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradientColorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGradientColorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGradientColorsQuery,
    GetGradientColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetGradientColorsQuery,
    GetGradientColorsQueryVariables
  >(GetGradientColorsDocument, options)
}
export function useGetGradientColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGradientColorsQuery,
    GetGradientColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetGradientColorsQuery,
    GetGradientColorsQueryVariables
  >(GetGradientColorsDocument, options)
}
export type GetGradientColorsQueryHookResult = ReturnType<
  typeof useGetGradientColorsQuery
>
export type GetGradientColorsLazyQueryHookResult = ReturnType<
  typeof useGetGradientColorsLazyQuery
>
export type GetGradientColorsQueryResult = Apollo.QueryResult<
  GetGradientColorsQuery,
  GetGradientColorsQueryVariables
>
export const GetBrandColorsDocument = gql`
  query getBrandColors($search: ColorsInput!) {
    data: getBrandColors(input: $search) {
      _id
      color
    }
  }
`

/**
 * __useGetBrandColorsQuery__
 *
 * To run a query within a React component, call `useGetBrandColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandColorsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetBrandColorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrandColorsQuery,
    GetBrandColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBrandColorsQuery, GetBrandColorsQueryVariables>(
    GetBrandColorsDocument,
    options,
  )
}
export function useGetBrandColorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandColorsQuery,
    GetBrandColorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBrandColorsQuery, GetBrandColorsQueryVariables>(
    GetBrandColorsDocument,
    options,
  )
}
export type GetBrandColorsQueryHookResult = ReturnType<
  typeof useGetBrandColorsQuery
>
export type GetBrandColorsLazyQueryHookResult = ReturnType<
  typeof useGetBrandColorsLazyQuery
>
export type GetBrandColorsQueryResult = Apollo.QueryResult<
  GetBrandColorsQuery,
  GetBrandColorsQueryVariables
>
export const VideoCreditPaymentIntentDocument = gql`
  mutation videoCreditPaymentIntent($input: PaymentIntent!) {
    videoCreditPaymentIntent(input: $input) {
      token
      status
    }
  }
`
export type VideoCreditPaymentIntentMutationFn = Apollo.MutationFunction<
  VideoCreditPaymentIntentMutation,
  VideoCreditPaymentIntentMutationVariables
>

/**
 * __useVideoCreditPaymentIntentMutation__
 *
 * To run a mutation, you first call `useVideoCreditPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoCreditPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoCreditPaymentIntentMutation, { data, loading, error }] = useVideoCreditPaymentIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoCreditPaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoCreditPaymentIntentMutation,
    VideoCreditPaymentIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    VideoCreditPaymentIntentMutation,
    VideoCreditPaymentIntentMutationVariables
  >(VideoCreditPaymentIntentDocument, options)
}
export type VideoCreditPaymentIntentMutationHookResult = ReturnType<
  typeof useVideoCreditPaymentIntentMutation
>
export type VideoCreditPaymentIntentMutationResult =
  Apollo.MutationResult<VideoCreditPaymentIntentMutation>
export type VideoCreditPaymentIntentMutationOptions =
  Apollo.BaseMutationOptions<
    VideoCreditPaymentIntentMutation,
    VideoCreditPaymentIntentMutationVariables
  >
export const CreditsListDocument = gql`
  query creditsList {
    creditsList {
      id
      price
      credits
      name
    }
  }
`

/**
 * __useCreditsListQuery__
 *
 * To run a query within a React component, call `useCreditsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CreditsListQuery,
    CreditsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CreditsListQuery, CreditsListQueryVariables>(
    CreditsListDocument,
    options,
  )
}
export function useCreditsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CreditsListQuery,
    CreditsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CreditsListQuery, CreditsListQueryVariables>(
    CreditsListDocument,
    options,
  )
}
export type CreditsListQueryHookResult = ReturnType<typeof useCreditsListQuery>
export type CreditsListLazyQueryHookResult = ReturnType<
  typeof useCreditsListLazyQuery
>
export type CreditsListQueryResult = Apollo.QueryResult<
  CreditsListQuery,
  CreditsListQueryVariables
>
export const SaveDraftDocument = gql`
  mutation saveDraft($input: DraftAddInput!) {
    saveDraft(input: $input) {
      _id
      title
    }
  }
`
export type SaveDraftMutationFn = Apollo.MutationFunction<
  SaveDraftMutation,
  SaveDraftMutationVariables
>

/**
 * __useSaveDraftMutation__
 *
 * To run a mutation, you first call `useSaveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftMutation, { data, loading, error }] = useSaveDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDraftMutation,
    SaveDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveDraftMutation, SaveDraftMutationVariables>(
    SaveDraftDocument,
    options,
  )
}
export type SaveDraftMutationHookResult = ReturnType<
  typeof useSaveDraftMutation
>
export type SaveDraftMutationResult = Apollo.MutationResult<SaveDraftMutation>
export type SaveDraftMutationOptions = Apollo.BaseMutationOptions<
  SaveDraftMutation,
  SaveDraftMutationVariables
>
export const RemovePremiumElementDocument = gql`
  mutation removePremiumElement($input: RemovePremiumElementsInput!) {
    removePremiumElement(input: $input) {
      message
      status
    }
  }
`
export type RemovePremiumElementMutationFn = Apollo.MutationFunction<
  RemovePremiumElementMutation,
  RemovePremiumElementMutationVariables
>

/**
 * __useRemovePremiumElementMutation__
 *
 * To run a mutation, you first call `useRemovePremiumElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePremiumElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePremiumElementMutation, { data, loading, error }] = useRemovePremiumElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePremiumElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemovePremiumElementMutation,
    RemovePremiumElementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemovePremiumElementMutation,
    RemovePremiumElementMutationVariables
  >(RemovePremiumElementDocument, options)
}
export type RemovePremiumElementMutationHookResult = ReturnType<
  typeof useRemovePremiumElementMutation
>
export type RemovePremiumElementMutationResult =
  Apollo.MutationResult<RemovePremiumElementMutation>
export type RemovePremiumElementMutationOptions = Apollo.BaseMutationOptions<
  RemovePremiumElementMutation,
  RemovePremiumElementMutationVariables
>
export const CreateDraftDocument = gql`
  mutation createDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      id: _id
    }
  }
`
export type CreateDraftMutationFn = Apollo.MutationFunction<
  CreateDraftMutation,
  CreateDraftMutationVariables
>

/**
 * __useCreateDraftMutation__
 *
 * To run a mutation, you first call `useCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftMutation, { data, loading, error }] = useCreateDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftMutation,
    CreateDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDraftMutation, CreateDraftMutationVariables>(
    CreateDraftDocument,
    options,
  )
}
export type CreateDraftMutationHookResult = ReturnType<
  typeof useCreateDraftMutation
>
export type CreateDraftMutationResult =
  Apollo.MutationResult<CreateDraftMutation>
export type CreateDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftMutation,
  CreateDraftMutationVariables
>
export const GetDraftsDocument = gql`
  query getDrafts($input: DraftsGetInput! = {}) {
    getDrafts(input: $input) {
      draft
      title
      duration
      videoSize
      layout
      createDate
      updateDate
    }
  }
`

/**
 * __useGetDraftsQuery__
 *
 * To run a query within a React component, call `useGetDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDraftsQuery,
    GetDraftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options,
  )
}
export function useGetDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftsQuery,
    GetDraftsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDraftsQuery, GetDraftsQueryVariables>(
    GetDraftsDocument,
    options,
  )
}
export type GetDraftsQueryHookResult = ReturnType<typeof useGetDraftsQuery>
export type GetDraftsLazyQueryHookResult = ReturnType<
  typeof useGetDraftsLazyQuery
>
export type GetDraftsQueryResult = Apollo.QueryResult<
  GetDraftsQuery,
  GetDraftsQueryVariables
>
export const ReadPremiumElementsDocument = gql`
  query readPremiumElements($input: PremiumElementsInput!) {
    readPremiumElements(input: $input) {
      id
      title
      type
      thumbImage
      slideId
    }
  }
`

/**
 * __useReadPremiumElementsQuery__
 *
 * To run a query within a React component, call `useReadPremiumElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPremiumElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPremiumElementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadPremiumElementsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadPremiumElementsQuery,
    ReadPremiumElementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ReadPremiumElementsQuery,
    ReadPremiumElementsQueryVariables
  >(ReadPremiumElementsDocument, options)
}
export function useReadPremiumElementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadPremiumElementsQuery,
    ReadPremiumElementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ReadPremiumElementsQuery,
    ReadPremiumElementsQueryVariables
  >(ReadPremiumElementsDocument, options)
}
export type ReadPremiumElementsQueryHookResult = ReturnType<
  typeof useReadPremiumElementsQuery
>
export type ReadPremiumElementsLazyQueryHookResult = ReturnType<
  typeof useReadPremiumElementsLazyQuery
>
export type ReadPremiumElementsQueryResult = Apollo.QueryResult<
  ReadPremiumElementsQuery,
  ReadPremiumElementsQueryVariables
>
export const TrackElementUsedCountDocument = gql`
  mutation trackElementUsedCount($input: TrackElementInput!) {
    trackElementUsedCount(input: $input) {
      message
      status
    }
  }
`
export type TrackElementUsedCountMutationFn = Apollo.MutationFunction<
  TrackElementUsedCountMutation,
  TrackElementUsedCountMutationVariables
>

/**
 * __useTrackElementUsedCountMutation__
 *
 * To run a mutation, you first call `useTrackElementUsedCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackElementUsedCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackElementUsedCountMutation, { data, loading, error }] = useTrackElementUsedCountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackElementUsedCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackElementUsedCountMutation,
    TrackElementUsedCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TrackElementUsedCountMutation,
    TrackElementUsedCountMutationVariables
  >(TrackElementUsedCountDocument, options)
}
export type TrackElementUsedCountMutationHookResult = ReturnType<
  typeof useTrackElementUsedCountMutation
>
export type TrackElementUsedCountMutationResult =
  Apollo.MutationResult<TrackElementUsedCountMutation>
export type TrackElementUsedCountMutationOptions = Apollo.BaseMutationOptions<
  TrackElementUsedCountMutation,
  TrackElementUsedCountMutationVariables
>
export const GetElementsObjectsCategoriesDocument = gql`
  query getElementsObjectsCategories {
    objectsCategories: getObjectsCategories {
      _id
      name
      key
    }
  }
`

/**
 * __useGetElementsObjectsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetElementsObjectsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsObjectsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsObjectsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetElementsObjectsCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsObjectsCategoriesQuery,
    GetElementsObjectsCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetElementsObjectsCategoriesQuery,
    GetElementsObjectsCategoriesQueryVariables
  >(GetElementsObjectsCategoriesDocument, options)
}
export function useGetElementsObjectsCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsObjectsCategoriesQuery,
    GetElementsObjectsCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetElementsObjectsCategoriesQuery,
    GetElementsObjectsCategoriesQueryVariables
  >(GetElementsObjectsCategoriesDocument, options)
}
export type GetElementsObjectsCategoriesQueryHookResult = ReturnType<
  typeof useGetElementsObjectsCategoriesQuery
>
export type GetElementsObjectsCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetElementsObjectsCategoriesLazyQuery
>
export type GetElementsObjectsCategoriesQueryResult = Apollo.QueryResult<
  GetElementsObjectsCategoriesQuery,
  GetElementsObjectsCategoriesQueryVariables
>
export const GetElementsObjectsCategoriesWithObjectsDocument = gql`
  query getElementsObjectsCategoriesWithObjects {
    objectsCategories: getObjectsCategoriesWithObjects {
      _id
      name
      key
      isNew
      ObjectsData {
        _id
        name
        thumbUrl
        mainUrl
        width
        height
      }
    }
  }
`

/**
 * __useGetElementsObjectsCategoriesWithObjectsQuery__
 *
 * To run a query within a React component, call `useGetElementsObjectsCategoriesWithObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsObjectsCategoriesWithObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsObjectsCategoriesWithObjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetElementsObjectsCategoriesWithObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsObjectsCategoriesWithObjectsQuery,
    GetElementsObjectsCategoriesWithObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetElementsObjectsCategoriesWithObjectsQuery,
    GetElementsObjectsCategoriesWithObjectsQueryVariables
  >(GetElementsObjectsCategoriesWithObjectsDocument, options)
}
export function useGetElementsObjectsCategoriesWithObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsObjectsCategoriesWithObjectsQuery,
    GetElementsObjectsCategoriesWithObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetElementsObjectsCategoriesWithObjectsQuery,
    GetElementsObjectsCategoriesWithObjectsQueryVariables
  >(GetElementsObjectsCategoriesWithObjectsDocument, options)
}
export type GetElementsObjectsCategoriesWithObjectsQueryHookResult = ReturnType<
  typeof useGetElementsObjectsCategoriesWithObjectsQuery
>
export type GetElementsObjectsCategoriesWithObjectsLazyQueryHookResult =
  ReturnType<typeof useGetElementsObjectsCategoriesWithObjectsLazyQuery>
export type GetElementsObjectsCategoriesWithObjectsQueryResult =
  Apollo.QueryResult<
    GetElementsObjectsCategoriesWithObjectsQuery,
    GetElementsObjectsCategoriesWithObjectsQueryVariables
  >
export const GetElementsShapesCategoriesWithShapesDocument = gql`
  query getElementsShapesCategoriesWithShapes(
    $search: ShapesCategoryInput! = {}
  ) {
    shapesCategories: getShapesCategoriesWithShapes(input: $search) {
      _id
      name
      key
      isNew
      ShapesData {
        _id
        name
        thumbUrl
        mainUrl
        width
        height
        svg
      }
    }
  }
`

/**
 * __useGetElementsShapesCategoriesWithShapesQuery__
 *
 * To run a query within a React component, call `useGetElementsShapesCategoriesWithShapesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsShapesCategoriesWithShapesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsShapesCategoriesWithShapesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetElementsShapesCategoriesWithShapesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsShapesCategoriesWithShapesQuery,
    GetElementsShapesCategoriesWithShapesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetElementsShapesCategoriesWithShapesQuery,
    GetElementsShapesCategoriesWithShapesQueryVariables
  >(GetElementsShapesCategoriesWithShapesDocument, options)
}
export function useGetElementsShapesCategoriesWithShapesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsShapesCategoriesWithShapesQuery,
    GetElementsShapesCategoriesWithShapesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetElementsShapesCategoriesWithShapesQuery,
    GetElementsShapesCategoriesWithShapesQueryVariables
  >(GetElementsShapesCategoriesWithShapesDocument, options)
}
export type GetElementsShapesCategoriesWithShapesQueryHookResult = ReturnType<
  typeof useGetElementsShapesCategoriesWithShapesQuery
>
export type GetElementsShapesCategoriesWithShapesLazyQueryHookResult =
  ReturnType<typeof useGetElementsShapesCategoriesWithShapesLazyQuery>
export type GetElementsShapesCategoriesWithShapesQueryResult =
  Apollo.QueryResult<
    GetElementsShapesCategoriesWithShapesQuery,
    GetElementsShapesCategoriesWithShapesQueryVariables
  >
export const GetElementsShapesDocument = gql`
  query getElementsShapes($search: ShapesInput! = {}) {
    shapes: searchElementsShapes(input: $search) {
      _id
      name
      thumbUrl
      mainUrl
      width
      height
      svg
    }
  }
`

/**
 * __useGetElementsShapesQuery__
 *
 * To run a query within a React component, call `useGetElementsShapesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsShapesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsShapesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetElementsShapesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsShapesQuery,
    GetElementsShapesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetElementsShapesQuery,
    GetElementsShapesQueryVariables
  >(GetElementsShapesDocument, options)
}
export function useGetElementsShapesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsShapesQuery,
    GetElementsShapesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetElementsShapesQuery,
    GetElementsShapesQueryVariables
  >(GetElementsShapesDocument, options)
}
export type GetElementsShapesQueryHookResult = ReturnType<
  typeof useGetElementsShapesQuery
>
export type GetElementsShapesLazyQueryHookResult = ReturnType<
  typeof useGetElementsShapesLazyQuery
>
export type GetElementsShapesQueryResult = Apollo.QueryResult<
  GetElementsShapesQuery,
  GetElementsShapesQueryVariables
>
export const GetElementsObjectsDocument = gql`
  query getElementsObjects($search: ObjectsInput! = {}) {
    objects: searchElementsObjects(input: $search) {
      _id
      name
      thumbUrl
      mainUrl
      width
      height
    }
  }
`

/**
 * __useGetElementsObjectsQuery__
 *
 * To run a query within a React component, call `useGetElementsObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetElementsObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetElementsObjectsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetElementsObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetElementsObjectsQuery,
    GetElementsObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetElementsObjectsQuery,
    GetElementsObjectsQueryVariables
  >(GetElementsObjectsDocument, options)
}
export function useGetElementsObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetElementsObjectsQuery,
    GetElementsObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetElementsObjectsQuery,
    GetElementsObjectsQueryVariables
  >(GetElementsObjectsDocument, options)
}
export type GetElementsObjectsQueryHookResult = ReturnType<
  typeof useGetElementsObjectsQuery
>
export type GetElementsObjectsLazyQueryHookResult = ReturnType<
  typeof useGetElementsObjectsLazyQuery
>
export type GetElementsObjectsQueryResult = Apollo.QueryResult<
  GetElementsObjectsQuery,
  GetElementsObjectsQueryVariables
>
export const AddFavouriteMusicDocument = gql`
  mutation addFavouriteMusic($data: FavouriteMusicAddInput!) {
    addFavouriteMusic(input: $data) {
      status
      message
    }
  }
`
export type AddFavouriteMusicMutationFn = Apollo.MutationFunction<
  AddFavouriteMusicMutation,
  AddFavouriteMusicMutationVariables
>

/**
 * __useAddFavouriteMusicMutation__
 *
 * To run a mutation, you first call `useAddFavouriteMusicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavouriteMusicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavouriteMusicMutation, { data, loading, error }] = useAddFavouriteMusicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddFavouriteMusicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavouriteMusicMutation,
    AddFavouriteMusicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddFavouriteMusicMutation,
    AddFavouriteMusicMutationVariables
  >(AddFavouriteMusicDocument, options)
}
export type AddFavouriteMusicMutationHookResult = ReturnType<
  typeof useAddFavouriteMusicMutation
>
export type AddFavouriteMusicMutationResult =
  Apollo.MutationResult<AddFavouriteMusicMutation>
export type AddFavouriteMusicMutationOptions = Apollo.BaseMutationOptions<
  AddFavouriteMusicMutation,
  AddFavouriteMusicMutationVariables
>
export const RemoveFavouriteMusicDocument = gql`
  mutation removeFavouriteMusic($data: FavouriteMusicRemoveInput!) {
    removeFavouriteMusic(input: $data) {
      status
      message
    }
  }
`
export type RemoveFavouriteMusicMutationFn = Apollo.MutationFunction<
  RemoveFavouriteMusicMutation,
  RemoveFavouriteMusicMutationVariables
>

/**
 * __useRemoveFavouriteMusicMutation__
 *
 * To run a mutation, you first call `useRemoveFavouriteMusicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavouriteMusicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavouriteMusicMutation, { data, loading, error }] = useRemoveFavouriteMusicMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveFavouriteMusicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFavouriteMusicMutation,
    RemoveFavouriteMusicMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveFavouriteMusicMutation,
    RemoveFavouriteMusicMutationVariables
  >(RemoveFavouriteMusicDocument, options)
}
export type RemoveFavouriteMusicMutationHookResult = ReturnType<
  typeof useRemoveFavouriteMusicMutation
>
export type RemoveFavouriteMusicMutationResult =
  Apollo.MutationResult<RemoveFavouriteMusicMutation>
export type RemoveFavouriteMusicMutationOptions = Apollo.BaseMutationOptions<
  RemoveFavouriteMusicMutation,
  RemoveFavouriteMusicMutationVariables
>
export const GetFavouriteMusicListDocument = gql`
  query getFavouriteMusicList($input: FavouriteMusicInput!) {
    filterFavoriteMusic(input: $input) {
      _id
      userId
      type
      referenceId
      melodie {
        id
        title
        url
        bpm
        duration
      }
      uploaded {
        name
        fileName
        url
        duration
      }
    }
  }
`

/**
 * __useGetFavouriteMusicListQuery__
 *
 * To run a query within a React component, call `useGetFavouriteMusicListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavouriteMusicListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavouriteMusicListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFavouriteMusicListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFavouriteMusicListQuery,
    GetFavouriteMusicListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetFavouriteMusicListQuery,
    GetFavouriteMusicListQueryVariables
  >(GetFavouriteMusicListDocument, options)
}
export function useGetFavouriteMusicListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavouriteMusicListQuery,
    GetFavouriteMusicListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFavouriteMusicListQuery,
    GetFavouriteMusicListQueryVariables
  >(GetFavouriteMusicListDocument, options)
}
export type GetFavouriteMusicListQueryHookResult = ReturnType<
  typeof useGetFavouriteMusicListQuery
>
export type GetFavouriteMusicListLazyQueryHookResult = ReturnType<
  typeof useGetFavouriteMusicListLazyQuery
>
export type GetFavouriteMusicListQueryResult = Apollo.QueryResult<
  GetFavouriteMusicListQuery,
  GetFavouriteMusicListQueryVariables
>
export const ChangeFontDataDocument = gql`
  mutation changeFontData($input: FontUseInput!) {
    changeFontData(input: $input) {
      message
      status
      type
    }
  }
`
export type ChangeFontDataMutationFn = Apollo.MutationFunction<
  ChangeFontDataMutation,
  ChangeFontDataMutationVariables
>

/**
 * __useChangeFontDataMutation__
 *
 * To run a mutation, you first call `useChangeFontDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFontDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFontDataMutation, { data, loading, error }] = useChangeFontDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeFontDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeFontDataMutation,
    ChangeFontDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeFontDataMutation,
    ChangeFontDataMutationVariables
  >(ChangeFontDataDocument, options)
}
export type ChangeFontDataMutationHookResult = ReturnType<
  typeof useChangeFontDataMutation
>
export type ChangeFontDataMutationResult =
  Apollo.MutationResult<ChangeFontDataMutation>
export type ChangeFontDataMutationOptions = Apollo.BaseMutationOptions<
  ChangeFontDataMutation,
  ChangeFontDataMutationVariables
>
export const SearchFontsDocument = gql`
  query searchFonts($search: FontsInput!) {
    searchFonts(input: $search) {
      _id
      family
      fontsUrl
      imageUrl
      imageExampleUrl
      svg
      source
      isPremium
    }
  }
`

/**
 * __useSearchFontsQuery__
 *
 * To run a query within a React component, call `useSearchFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFontsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchFontsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchFontsQuery,
    SearchFontsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchFontsQuery, SearchFontsQueryVariables>(
    SearchFontsDocument,
    options,
  )
}
export function useSearchFontsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFontsQuery,
    SearchFontsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchFontsQuery, SearchFontsQueryVariables>(
    SearchFontsDocument,
    options,
  )
}
export type SearchFontsQueryHookResult = ReturnType<typeof useSearchFontsQuery>
export type SearchFontsLazyQueryHookResult = ReturnType<
  typeof useSearchFontsLazyQuery
>
export type SearchFontsQueryResult = Apollo.QueryResult<
  SearchFontsQuery,
  SearchFontsQueryVariables
>
export const GetGiphyAssetsDocument = gql`
  query GetGiphyAssets($search: GiphyFilterInput! = {}) {
    giphyAssets: getGiphyAssets(input: $search) {
      id
      url
      width
      height
    }
  }
`

/**
 * __useGetGiphyAssetsQuery__
 *
 * To run a query within a React component, call `useGetGiphyAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiphyAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiphyAssetsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetGiphyAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGiphyAssetsQuery,
    GetGiphyAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGiphyAssetsQuery, GetGiphyAssetsQueryVariables>(
    GetGiphyAssetsDocument,
    options,
  )
}
export function useGetGiphyAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGiphyAssetsQuery,
    GetGiphyAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGiphyAssetsQuery, GetGiphyAssetsQueryVariables>(
    GetGiphyAssetsDocument,
    options,
  )
}
export type GetGiphyAssetsQueryHookResult = ReturnType<
  typeof useGetGiphyAssetsQuery
>
export type GetGiphyAssetsLazyQueryHookResult = ReturnType<
  typeof useGetGiphyAssetsLazyQuery
>
export type GetGiphyAssetsQueryResult = Apollo.QueryResult<
  GetGiphyAssetsQuery,
  GetGiphyAssetsQueryVariables
>
export const AddMediaDocument = gql`
  mutation addMedia($input: AddMediaInput!) {
    addMedia(input: $input) {
      uploadStatus
      message
      name
      id
      presignedUrl
    }
  }
`
export type AddMediaMutationFn = Apollo.MutationFunction<
  AddMediaMutation,
  AddMediaMutationVariables
>

/**
 * __useAddMediaMutation__
 *
 * To run a mutation, you first call `useAddMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaMutation, { data, loading, error }] = useAddMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMediaMutation,
    AddMediaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddMediaMutation, AddMediaMutationVariables>(
    AddMediaDocument,
    options,
  )
}
export type AddMediaMutationHookResult = ReturnType<typeof useAddMediaMutation>
export type AddMediaMutationResult = Apollo.MutationResult<AddMediaMutation>
export type AddMediaMutationOptions = Apollo.BaseMutationOptions<
  AddMediaMutation,
  AddMediaMutationVariables
>
export const RemoveMediaDocument = gql`
  mutation removeMedia($input: RemoveMediaInput!) {
    removeMedia(input: $input) {
      _id
      uniqueId
    }
  }
`
export type RemoveMediaMutationFn = Apollo.MutationFunction<
  RemoveMediaMutation,
  RemoveMediaMutationVariables
>

/**
 * __useRemoveMediaMutation__
 *
 * To run a mutation, you first call `useRemoveMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaMutation, { data, loading, error }] = useRemoveMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMediaMutation,
    RemoveMediaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveMediaMutation, RemoveMediaMutationVariables>(
    RemoveMediaDocument,
    options,
  )
}
export type RemoveMediaMutationHookResult = ReturnType<
  typeof useRemoveMediaMutation
>
export type RemoveMediaMutationResult =
  Apollo.MutationResult<RemoveMediaMutation>
export type RemoveMediaMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaMutation,
  RemoveMediaMutationVariables
>
export const ListMediaDocument = gql`
  query listMedia($data: ListMediaInput!) {
    listMedia(input: $data) {
      _id
      url
      name
      userId
      width
      height
      ratio
      thumbnail: thumbnailUrl
      favourite
      duration
      fileName
      uniqueId
      uploadStatus
      progress
      mediaType
    }
  }
`

/**
 * __useListMediaQuery__
 *
 * To run a query within a React component, call `useListMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMediaQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useListMediaQuery(
  baseOptions: Apollo.QueryHookOptions<ListMediaQuery, ListMediaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListMediaQuery, ListMediaQueryVariables>(
    ListMediaDocument,
    options,
  )
}
export function useListMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMediaQuery,
    ListMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListMediaQuery, ListMediaQueryVariables>(
    ListMediaDocument,
    options,
  )
}
export type ListMediaQueryHookResult = ReturnType<typeof useListMediaQuery>
export type ListMediaLazyQueryHookResult = ReturnType<
  typeof useListMediaLazyQuery
>
export type ListMediaQueryResult = Apollo.QueryResult<
  ListMediaQuery,
  ListMediaQueryVariables
>
export const GetMelodieAudiosDocument = gql`
  query GetMelodieAudios($search: MelodieAudioInput! = {}) {
    melodieAudios: searchMelodieAudio(input: $search) {
      id
      title
      description
      url
      bpm
      duration
      waveformUrl
      favourite
    }
  }
`

/**
 * __useGetMelodieAudiosQuery__
 *
 * To run a query within a React component, call `useGetMelodieAudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMelodieAudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMelodieAudiosQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetMelodieAudiosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMelodieAudiosQuery,
    GetMelodieAudiosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMelodieAudiosQuery, GetMelodieAudiosQueryVariables>(
    GetMelodieAudiosDocument,
    options,
  )
}
export function useGetMelodieAudiosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMelodieAudiosQuery,
    GetMelodieAudiosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMelodieAudiosQuery,
    GetMelodieAudiosQueryVariables
  >(GetMelodieAudiosDocument, options)
}
export type GetMelodieAudiosQueryHookResult = ReturnType<
  typeof useGetMelodieAudiosQuery
>
export type GetMelodieAudiosLazyQueryHookResult = ReturnType<
  typeof useGetMelodieAudiosLazyQuery
>
export type GetMelodieAudiosQueryResult = Apollo.QueryResult<
  GetMelodieAudiosQuery,
  GetMelodieAudiosQueryVariables
>
export const GetPeacockVideosCategoriesDocument = gql`
  query getPeacockVideosCategories {
    categories: getPeacockVideosCategories {
      _id
      name
      key
    }
  }
`

/**
 * __useGetPeacockVideosCategoriesQuery__
 *
 * To run a query within a React component, call `useGetPeacockVideosCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeacockVideosCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeacockVideosCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPeacockVideosCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPeacockVideosCategoriesQuery,
    GetPeacockVideosCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPeacockVideosCategoriesQuery,
    GetPeacockVideosCategoriesQueryVariables
  >(GetPeacockVideosCategoriesDocument, options)
}
export function useGetPeacockVideosCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeacockVideosCategoriesQuery,
    GetPeacockVideosCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPeacockVideosCategoriesQuery,
    GetPeacockVideosCategoriesQueryVariables
  >(GetPeacockVideosCategoriesDocument, options)
}
export type GetPeacockVideosCategoriesQueryHookResult = ReturnType<
  typeof useGetPeacockVideosCategoriesQuery
>
export type GetPeacockVideosCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetPeacockVideosCategoriesLazyQuery
>
export type GetPeacockVideosCategoriesQueryResult = Apollo.QueryResult<
  GetPeacockVideosCategoriesQuery,
  GetPeacockVideosCategoriesQueryVariables
>
export const GetPeacockVideosDocument = gql`
  query getPeacockVideos($search: PeacockVideosInput! = {}) {
    videos: searchPeacockVideos(input: $search) {
      _id
      gifingId
      title
      mp4url
      watermarkmp4
      thumbnail
      duration
      hasAudio
    }
  }
`

/**
 * __useGetPeacockVideosQuery__
 *
 * To run a query within a React component, call `useGetPeacockVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeacockVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeacockVideosQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPeacockVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPeacockVideosQuery,
    GetPeacockVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPeacockVideosQuery, GetPeacockVideosQueryVariables>(
    GetPeacockVideosDocument,
    options,
  )
}
export function useGetPeacockVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeacockVideosQuery,
    GetPeacockVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPeacockVideosQuery,
    GetPeacockVideosQueryVariables
  >(GetPeacockVideosDocument, options)
}
export type GetPeacockVideosQueryHookResult = ReturnType<
  typeof useGetPeacockVideosQuery
>
export type GetPeacockVideosLazyQueryHookResult = ReturnType<
  typeof useGetPeacockVideosLazyQuery
>
export type GetPeacockVideosQueryResult = Apollo.QueryResult<
  GetPeacockVideosQuery,
  GetPeacockVideosQueryVariables
>
export const GetSinglePeacockVideoDocument = gql`
  query getSinglePeacockVideo($search: PeacockVideoInput!) {
    video: getPeacockVideo(input: $search) {
      _id
      gifingId
      title
      keywords
      mp4url
      watermarkmp4
      thumbnail
      duration
      hasAudio
    }
  }
`

/**
 * __useGetSinglePeacockVideoQuery__
 *
 * To run a query within a React component, call `useGetSinglePeacockVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSinglePeacockVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSinglePeacockVideoQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetSinglePeacockVideoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSinglePeacockVideoQuery,
    GetSinglePeacockVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSinglePeacockVideoQuery,
    GetSinglePeacockVideoQueryVariables
  >(GetSinglePeacockVideoDocument, options)
}
export function useGetSinglePeacockVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSinglePeacockVideoQuery,
    GetSinglePeacockVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSinglePeacockVideoQuery,
    GetSinglePeacockVideoQueryVariables
  >(GetSinglePeacockVideoDocument, options)
}
export type GetSinglePeacockVideoQueryHookResult = ReturnType<
  typeof useGetSinglePeacockVideoQuery
>
export type GetSinglePeacockVideoLazyQueryHookResult = ReturnType<
  typeof useGetSinglePeacockVideoLazyQuery
>
export type GetSinglePeacockVideoQueryResult = Apollo.QueryResult<
  GetSinglePeacockVideoQuery,
  GetSinglePeacockVideoQueryVariables
>
export const GetPexelsImagesDocument = gql`
  query GetPexelsImages($search: PexelsImageInput! = {}) {
    pexelsImages: searchPexelsImage(input: $search) {
      id
      url
      preview_url
      description
      width
      height
    }
  }
`

/**
 * __useGetPexelsImagesQuery__
 *
 * To run a query within a React component, call `useGetPexelsImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPexelsImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPexelsImagesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPexelsImagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPexelsImagesQuery,
    GetPexelsImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPexelsImagesQuery, GetPexelsImagesQueryVariables>(
    GetPexelsImagesDocument,
    options,
  )
}
export function useGetPexelsImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPexelsImagesQuery,
    GetPexelsImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPexelsImagesQuery,
    GetPexelsImagesQueryVariables
  >(GetPexelsImagesDocument, options)
}
export type GetPexelsImagesQueryHookResult = ReturnType<
  typeof useGetPexelsImagesQuery
>
export type GetPexelsImagesLazyQueryHookResult = ReturnType<
  typeof useGetPexelsImagesLazyQuery
>
export type GetPexelsImagesQueryResult = Apollo.QueryResult<
  GetPexelsImagesQuery,
  GetPexelsImagesQueryVariables
>
export const GetPexelsVideosDocument = gql`
  query GetPexelsVideos($search: PexelsVideoInput! = {}) {
    pexelsVideos: searchPexelsVideo(input: $search) {
      id
      description
      hd_url
      sd_url
      thumbnail
      duration
      width
      height
      aspect_ratio
      collection
    }
  }
`

/**
 * __useGetPexelsVideosQuery__
 *
 * To run a query within a React component, call `useGetPexelsVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPexelsVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPexelsVideosQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPexelsVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPexelsVideosQuery,
    GetPexelsVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPexelsVideosQuery, GetPexelsVideosQueryVariables>(
    GetPexelsVideosDocument,
    options,
  )
}
export function useGetPexelsVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPexelsVideosQuery,
    GetPexelsVideosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPexelsVideosQuery,
    GetPexelsVideosQueryVariables
  >(GetPexelsVideosDocument, options)
}
export type GetPexelsVideosQueryHookResult = ReturnType<
  typeof useGetPexelsVideosQuery
>
export type GetPexelsVideosLazyQueryHookResult = ReturnType<
  typeof useGetPexelsVideosLazyQuery
>
export type GetPexelsVideosQueryResult = Apollo.QueryResult<
  GetPexelsVideosQuery,
  GetPexelsVideosQueryVariables
>
export const AddRecentDocument = gql`
  mutation addRecent($input: addRecentsInput!) {
    addRecent(input: $input) {
      assetId
    }
  }
`
export type AddRecentMutationFn = Apollo.MutationFunction<
  AddRecentMutation,
  AddRecentMutationVariables
>

/**
 * __useAddRecentMutation__
 *
 * To run a mutation, you first call `useAddRecentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecentMutation, { data, loading, error }] = useAddRecentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRecentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecentMutation,
    AddRecentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddRecentMutation, AddRecentMutationVariables>(
    AddRecentDocument,
    options,
  )
}
export type AddRecentMutationHookResult = ReturnType<
  typeof useAddRecentMutation
>
export type AddRecentMutationResult = Apollo.MutationResult<AddRecentMutation>
export type AddRecentMutationOptions = Apollo.BaseMutationOptions<
  AddRecentMutation,
  AddRecentMutationVariables
>
export const RemoveRecentDocument = gql`
  mutation removeRecent($input: removeRecentsInput!) {
    removeRecent(input: $input) {
      assetId
    }
  }
`
export type RemoveRecentMutationFn = Apollo.MutationFunction<
  RemoveRecentMutation,
  RemoveRecentMutationVariables
>

/**
 * __useRemoveRecentMutation__
 *
 * To run a mutation, you first call `useRemoveRecentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecentMutation, { data, loading, error }] = useRemoveRecentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRecentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRecentMutation,
    RemoveRecentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveRecentMutation,
    RemoveRecentMutationVariables
  >(RemoveRecentDocument, options)
}
export type RemoveRecentMutationHookResult = ReturnType<
  typeof useRemoveRecentMutation
>
export type RemoveRecentMutationResult =
  Apollo.MutationResult<RemoveRecentMutation>
export type RemoveRecentMutationOptions = Apollo.BaseMutationOptions<
  RemoveRecentMutation,
  RemoveRecentMutationVariables
>
export const RecentsListDocument = gql`
  query recentsList($data: RecentsInput!) {
    recentsList(input: $data) {
      assetId
      title
      author
      authorPage
      content
      description
      duration
      thumbnail
      bpm
      ratio
      width
      height
      type
      source
      favourite
    }
  }
`

/**
 * __useRecentsListQuery__
 *
 * To run a query within a React component, call `useRecentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentsListQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRecentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentsListQuery,
    RecentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RecentsListQuery, RecentsListQueryVariables>(
    RecentsListDocument,
    options,
  )
}
export function useRecentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentsListQuery,
    RecentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RecentsListQuery, RecentsListQueryVariables>(
    RecentsListDocument,
    options,
  )
}
export type RecentsListQueryHookResult = ReturnType<typeof useRecentsListQuery>
export type RecentsListLazyQueryHookResult = ReturnType<
  typeof useRecentsListLazyQuery
>
export type RecentsListQueryResult = Apollo.QueryResult<
  RecentsListQuery,
  RecentsListQueryVariables
>
export const RemoveBackgroundDocument = gql`
  mutation RemoveBackground($in: RemoveBackgroundInput!) {
    data: removeMediaBackground(input: $in)
  }
`
export type RemoveBackgroundMutationFn = Apollo.MutationFunction<
  RemoveBackgroundMutation,
  RemoveBackgroundMutationVariables
>

/**
 * __useRemoveBackgroundMutation__
 *
 * To run a mutation, you first call `useRemoveBackgroundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBackgroundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBackgroundMutation, { data, loading, error }] = useRemoveBackgroundMutation({
 *   variables: {
 *      in: // value for 'in'
 *   },
 * });
 */
export function useRemoveBackgroundMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBackgroundMutation,
    RemoveBackgroundMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveBackgroundMutation,
    RemoveBackgroundMutationVariables
  >(RemoveBackgroundDocument, options)
}
export type RemoveBackgroundMutationHookResult = ReturnType<
  typeof useRemoveBackgroundMutation
>
export type RemoveBackgroundMutationResult =
  Apollo.MutationResult<RemoveBackgroundMutation>
export type RemoveBackgroundMutationOptions = Apollo.BaseMutationOptions<
  RemoveBackgroundMutation,
  RemoveBackgroundMutationVariables
>
export const PostVideoOnsocialPlatformDocument = gql`
  mutation postVideoOnsocialPlatform($input: postSocialMediaInput!) {
    postVideoOnSocial(input: $input) {
      status
      message
      url
      post_id
    }
  }
`
export type PostVideoOnsocialPlatformMutationFn = Apollo.MutationFunction<
  PostVideoOnsocialPlatformMutation,
  PostVideoOnsocialPlatformMutationVariables
>

/**
 * __usePostVideoOnsocialPlatformMutation__
 *
 * To run a mutation, you first call `usePostVideoOnsocialPlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostVideoOnsocialPlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postVideoOnsocialPlatformMutation, { data, loading, error }] = usePostVideoOnsocialPlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostVideoOnsocialPlatformMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostVideoOnsocialPlatformMutation,
    PostVideoOnsocialPlatformMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PostVideoOnsocialPlatformMutation,
    PostVideoOnsocialPlatformMutationVariables
  >(PostVideoOnsocialPlatformDocument, options)
}
export type PostVideoOnsocialPlatformMutationHookResult = ReturnType<
  typeof usePostVideoOnsocialPlatformMutation
>
export type PostVideoOnsocialPlatformMutationResult =
  Apollo.MutationResult<PostVideoOnsocialPlatformMutation>
export type PostVideoOnsocialPlatformMutationOptions =
  Apollo.BaseMutationOptions<
    PostVideoOnsocialPlatformMutation,
    PostVideoOnsocialPlatformMutationVariables
  >
export const GetAuthLoginUrlDocument = gql`
  query getAuthLoginURL($search: SocialAccountLoginInput!) {
    getAuthLoginURL(input: $search) {
      status
      url
      message
    }
  }
`

/**
 * __useGetAuthLoginUrlQuery__
 *
 * To run a query within a React component, call `useGetAuthLoginUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthLoginUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthLoginUrlQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAuthLoginUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthLoginUrlQuery,
    GetAuthLoginUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAuthLoginUrlQuery, GetAuthLoginUrlQueryVariables>(
    GetAuthLoginUrlDocument,
    options,
  )
}
export function useGetAuthLoginUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthLoginUrlQuery,
    GetAuthLoginUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAuthLoginUrlQuery,
    GetAuthLoginUrlQueryVariables
  >(GetAuthLoginUrlDocument, options)
}
export type GetAuthLoginUrlQueryHookResult = ReturnType<
  typeof useGetAuthLoginUrlQuery
>
export type GetAuthLoginUrlLazyQueryHookResult = ReturnType<
  typeof useGetAuthLoginUrlLazyQuery
>
export type GetAuthLoginUrlQueryResult = Apollo.QueryResult<
  GetAuthLoginUrlQuery,
  GetAuthLoginUrlQueryVariables
>
export const GetAllSocialAccountsDocument = gql`
  query getAllSocialAccounts {
    getSocialAccounts {
      facebook {
        data {
          accountId
          metaData {
            id
            name
          }
          pageData {
            page_id
            name
          }
        }
      }
      instagram {
        data {
          accountId
          metaData {
            id
            name
          }
          pageData {
            page_id
            name
          }
        }
      }
      tiktok {
        data {
          accountId
          metaData {
            id
            name
          }
          pageData {
            page_id
            name
          }
        }
      }
      youtube {
        data {
          accountId
          metaData {
            id
            name
          }
          pageData {
            page_id
            name
          }
        }
      }
    }
  }
`

/**
 * __useGetAllSocialAccountsQuery__
 *
 * To run a query within a React component, call `useGetAllSocialAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSocialAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSocialAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSocialAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSocialAccountsQuery,
    GetAllSocialAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllSocialAccountsQuery,
    GetAllSocialAccountsQueryVariables
  >(GetAllSocialAccountsDocument, options)
}
export function useGetAllSocialAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSocialAccountsQuery,
    GetAllSocialAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllSocialAccountsQuery,
    GetAllSocialAccountsQueryVariables
  >(GetAllSocialAccountsDocument, options)
}
export type GetAllSocialAccountsQueryHookResult = ReturnType<
  typeof useGetAllSocialAccountsQuery
>
export type GetAllSocialAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAllSocialAccountsLazyQuery
>
export type GetAllSocialAccountsQueryResult = Apollo.QueryResult<
  GetAllSocialAccountsQuery,
  GetAllSocialAccountsQueryVariables
>
export const GetAllSocialConnectedAccountsCountDocument = gql`
  query getAllSocialConnectedAccountsCount {
    getSocialAccounts {
      facebook {
        count
      }
      instagram {
        count
      }
      tiktok {
        count
      }
      youtube {
        count
      }
    }
  }
`

/**
 * __useGetAllSocialConnectedAccountsCountQuery__
 *
 * To run a query within a React component, call `useGetAllSocialConnectedAccountsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSocialConnectedAccountsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSocialConnectedAccountsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSocialConnectedAccountsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSocialConnectedAccountsCountQuery,
    GetAllSocialConnectedAccountsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAllSocialConnectedAccountsCountQuery,
    GetAllSocialConnectedAccountsCountQueryVariables
  >(GetAllSocialConnectedAccountsCountDocument, options)
}
export function useGetAllSocialConnectedAccountsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSocialConnectedAccountsCountQuery,
    GetAllSocialConnectedAccountsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAllSocialConnectedAccountsCountQuery,
    GetAllSocialConnectedAccountsCountQueryVariables
  >(GetAllSocialConnectedAccountsCountDocument, options)
}
export type GetAllSocialConnectedAccountsCountQueryHookResult = ReturnType<
  typeof useGetAllSocialConnectedAccountsCountQuery
>
export type GetAllSocialConnectedAccountsCountLazyQueryHookResult = ReturnType<
  typeof useGetAllSocialConnectedAccountsCountLazyQuery
>
export type GetAllSocialConnectedAccountsCountQueryResult = Apollo.QueryResult<
  GetAllSocialConnectedAccountsCountQuery,
  GetAllSocialConnectedAccountsCountQueryVariables
>
export const GetSocialAccountDocument = gql`
  query getSocialAccount($search: SocialAccountInput!) {
    getSocialAccount(input: $search) {
      accountId
      metaData {
        id
        name
        picture
      }
      pageData {
        page_id
        name
      }
      service
    }
  }
`

/**
 * __useGetSocialAccountQuery__
 *
 * To run a query within a React component, call `useGetSocialAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSocialAccountQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetSocialAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSocialAccountQuery,
    GetSocialAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSocialAccountQuery, GetSocialAccountQueryVariables>(
    GetSocialAccountDocument,
    options,
  )
}
export function useGetSocialAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSocialAccountQuery,
    GetSocialAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSocialAccountQuery,
    GetSocialAccountQueryVariables
  >(GetSocialAccountDocument, options)
}
export type GetSocialAccountQueryHookResult = ReturnType<
  typeof useGetSocialAccountQuery
>
export type GetSocialAccountLazyQueryHookResult = ReturnType<
  typeof useGetSocialAccountLazyQuery
>
export type GetSocialAccountQueryResult = Apollo.QueryResult<
  GetSocialAccountQuery,
  GetSocialAccountQueryVariables
>
export const SearchTemplatesDocument = gql`
  query searchTemplates($search: SearchTemplatesInput!) {
    searchTemplates(input: $search) {
      _id
      videoSummary {
        title
        videoUrl
        thumbnail
        duration
        videoSize
      }
      isFavourite
    }
  }
`

/**
 * __useSearchTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTemplatesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchTemplatesQuery,
    SearchTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchTemplatesQuery, SearchTemplatesQueryVariables>(
    SearchTemplatesDocument,
    options,
  )
}
export function useSearchTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchTemplatesQuery,
    SearchTemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchTemplatesQuery,
    SearchTemplatesQueryVariables
  >(SearchTemplatesDocument, options)
}
export type SearchTemplatesQueryHookResult = ReturnType<
  typeof useSearchTemplatesQuery
>
export type SearchTemplatesLazyQueryHookResult = ReturnType<
  typeof useSearchTemplatesLazyQuery
>
export type SearchTemplatesQueryResult = Apollo.QueryResult<
  SearchTemplatesQuery,
  SearchTemplatesQueryVariables
>
export const SaveTemplateDocument = gql`
  mutation saveTemplate($input: SaveTemplateInput!) {
    saveTemplate(input: $input) {
      _id
      isPublished
    }
  }
`
export type SaveTemplateMutationFn = Apollo.MutationFunction<
  SaveTemplateMutation,
  SaveTemplateMutationVariables
>

/**
 * __useSaveTemplateMutation__
 *
 * To run a mutation, you first call `useSaveTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTemplateMutation, { data, loading, error }] = useSaveTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveTemplateMutation,
    SaveTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveTemplateMutation,
    SaveTemplateMutationVariables
  >(SaveTemplateDocument, options)
}
export type SaveTemplateMutationHookResult = ReturnType<
  typeof useSaveTemplateMutation
>
export type SaveTemplateMutationResult =
  Apollo.MutationResult<SaveTemplateMutation>
export type SaveTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveTemplateMutation,
  SaveTemplateMutationVariables
>
export const GetTemplateDocument = gql`
  query GetTemplate($input: GetTemplateInput!) {
    getTemplate(input: $input) {
      _id
      videoId
      keywords
      isPublished
    }
  }
`

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTemplateQuery,
    GetTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(
    GetTemplateDocument,
    options,
  )
}
export function useGetTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTemplateQuery,
    GetTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(
    GetTemplateDocument,
    options,
  )
}
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>
export type GetTemplateLazyQueryHookResult = ReturnType<
  typeof useGetTemplateLazyQuery
>
export type GetTemplateQueryResult = Apollo.QueryResult<
  GetTemplateQuery,
  GetTemplateQueryVariables
>
export const SearchUnsplashImageDocument = gql`
  query searchUnsplashImage($search: UnsplashInput!) {
    unsplashImages: searchUnsplashImage(input: $search) {
      id
      description
      authorName
      authorPage
      preview
      url
      width
      height
    }
  }
`

/**
 * __useSearchUnsplashImageQuery__
 *
 * To run a query within a React component, call `useSearchUnsplashImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnsplashImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnsplashImageQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchUnsplashImageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchUnsplashImageQuery,
    SearchUnsplashImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SearchUnsplashImageQuery,
    SearchUnsplashImageQueryVariables
  >(SearchUnsplashImageDocument, options)
}
export function useSearchUnsplashImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUnsplashImageQuery,
    SearchUnsplashImageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SearchUnsplashImageQuery,
    SearchUnsplashImageQueryVariables
  >(SearchUnsplashImageDocument, options)
}
export type SearchUnsplashImageQueryHookResult = ReturnType<
  typeof useSearchUnsplashImageQuery
>
export type SearchUnsplashImageLazyQueryHookResult = ReturnType<
  typeof useSearchUnsplashImageLazyQuery
>
export type SearchUnsplashImageQueryResult = Apollo.QueryResult<
  SearchUnsplashImageQuery,
  SearchUnsplashImageQueryVariables
>
export const SubscriptionIntentDocument = gql`
  mutation SubscriptionIntent($subscriptionIntent: SubscriptionInput!) {
    subscriptionIntent(input: $subscriptionIntent) {
      status
      token
      message
      planAction
      paymentBehavior
    }
  }
`
export type SubscriptionIntentMutationFn = Apollo.MutationFunction<
  SubscriptionIntentMutation,
  SubscriptionIntentMutationVariables
>

/**
 * __useSubscriptionIntentMutation__
 *
 * To run a mutation, you first call `useSubscriptionIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionIntentMutation, { data, loading, error }] = useSubscriptionIntentMutation({
 *   variables: {
 *      subscriptionIntent: // value for 'subscriptionIntent'
 *   },
 * });
 */
export function useSubscriptionIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscriptionIntentMutation,
    SubscriptionIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SubscriptionIntentMutation,
    SubscriptionIntentMutationVariables
  >(SubscriptionIntentDocument, options)
}
export type SubscriptionIntentMutationHookResult = ReturnType<
  typeof useSubscriptionIntentMutation
>
export type SubscriptionIntentMutationResult =
  Apollo.MutationResult<SubscriptionIntentMutation>
export type SubscriptionIntentMutationOptions = Apollo.BaseMutationOptions<
  SubscriptionIntentMutation,
  SubscriptionIntentMutationVariables
>
export const CancelIncompleteSubscriptionDocument = gql`
  mutation cancelIncompleteSubscription {
    cancelIncompleteSubscription {
      status
      message
    }
  }
`
export type CancelIncompleteSubscriptionMutationFn = Apollo.MutationFunction<
  CancelIncompleteSubscriptionMutation,
  CancelIncompleteSubscriptionMutationVariables
>

/**
 * __useCancelIncompleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelIncompleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelIncompleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelIncompleteSubscriptionMutation, { data, loading, error }] = useCancelIncompleteSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelIncompleteSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelIncompleteSubscriptionMutation,
    CancelIncompleteSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelIncompleteSubscriptionMutation,
    CancelIncompleteSubscriptionMutationVariables
  >(CancelIncompleteSubscriptionDocument, options)
}
export type CancelIncompleteSubscriptionMutationHookResult = ReturnType<
  typeof useCancelIncompleteSubscriptionMutation
>
export type CancelIncompleteSubscriptionMutationResult =
  Apollo.MutationResult<CancelIncompleteSubscriptionMutation>
export type CancelIncompleteSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    CancelIncompleteSubscriptionMutation,
    CancelIncompleteSubscriptionMutationVariables
  >
export const ConsumeCreditDocument = gql`
  mutation consumeCredit($consumeCreditInput: ConsumeCreditInput!) {
    consumeCredit(input: $consumeCreditInput) {
      status
      message
    }
  }
`
export type ConsumeCreditMutationFn = Apollo.MutationFunction<
  ConsumeCreditMutation,
  ConsumeCreditMutationVariables
>

/**
 * __useConsumeCreditMutation__
 *
 * To run a mutation, you first call `useConsumeCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsumeCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consumeCreditMutation, { data, loading, error }] = useConsumeCreditMutation({
 *   variables: {
 *      consumeCreditInput: // value for 'consumeCreditInput'
 *   },
 * });
 */
export function useConsumeCreditMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConsumeCreditMutation,
    ConsumeCreditMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConsumeCreditMutation,
    ConsumeCreditMutationVariables
  >(ConsumeCreditDocument, options)
}
export type ConsumeCreditMutationHookResult = ReturnType<
  typeof useConsumeCreditMutation
>
export type ConsumeCreditMutationResult =
  Apollo.MutationResult<ConsumeCreditMutation>
export type ConsumeCreditMutationOptions = Apollo.BaseMutationOptions<
  ConsumeCreditMutation,
  ConsumeCreditMutationVariables
>
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    currentUser {
      _id
      email
      name
      initials
      stripe {
        customerId
        paymentMethodId
        last4
      }
      lastName
      password
      picture
      role
      plan
      yearly
      subscriptionEndDate
      amountDue
      businessName
      credits {
        usedCredits
        maxCredits
        topupCredits
        usedTopup
      }
      address {
        city
        line1
        line2
        zipcode
        country
      }
      groupId
      subscriptionStatus
      webhookStatus
      scheduleSubscription {
        scheduleId
        planName
        price
      }
      isGhost
      seesWatermarkedAssets
      hasFreePlan
      hasProPlan
      hasUnlimitedPlan
      remainingCredits
      hasPlanOutSideBilling
      creditsLimit
      topupRemainingCredits
      planRemainingCredits
      userNeedsUpgrade
    }
  }
`

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  )
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  )
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>
export const CheckCouponDocument = gql`
  query CheckCoupon($coupon: CouponInput!) {
    checkCoupon(input: $coupon) {
      durationInMonth
      percentOff
      status
      products
    }
  }
`

/**
 * __useCheckCouponQuery__
 *
 * To run a query within a React component, call `useCheckCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCouponQuery({
 *   variables: {
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useCheckCouponQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckCouponQuery,
    CheckCouponQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CheckCouponQuery, CheckCouponQueryVariables>(
    CheckCouponDocument,
    options,
  )
}
export function useCheckCouponLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckCouponQuery,
    CheckCouponQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CheckCouponQuery, CheckCouponQueryVariables>(
    CheckCouponDocument,
    options,
  )
}
export type CheckCouponQueryHookResult = ReturnType<typeof useCheckCouponQuery>
export type CheckCouponLazyQueryHookResult = ReturnType<
  typeof useCheckCouponLazyQuery
>
export type CheckCouponQueryResult = Apollo.QueryResult<
  CheckCouponQuery,
  CheckCouponQueryVariables
>
export const GetStripeGroupPlansDocument = gql`
  query getStripeGroupPlans {
    getStripeGroupPlans {
      _id
      name
      label
      groupId
      mostPopular
      bestValue
      plans {
        _id
        name
        price
        billingType
        productId
      }
    }
  }
`

/**
 * __useGetStripeGroupPlansQuery__
 *
 * To run a query within a React component, call `useGetStripeGroupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeGroupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeGroupPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeGroupPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStripeGroupPlansQuery,
    GetStripeGroupPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStripeGroupPlansQuery,
    GetStripeGroupPlansQueryVariables
  >(GetStripeGroupPlansDocument, options)
}
export function useGetStripeGroupPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeGroupPlansQuery,
    GetStripeGroupPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStripeGroupPlansQuery,
    GetStripeGroupPlansQueryVariables
  >(GetStripeGroupPlansDocument, options)
}
export type GetStripeGroupPlansQueryHookResult = ReturnType<
  typeof useGetStripeGroupPlansQuery
>
export type GetStripeGroupPlansLazyQueryHookResult = ReturnType<
  typeof useGetStripeGroupPlansLazyQuery
>
export type GetStripeGroupPlansQueryResult = Apollo.QueryResult<
  GetStripeGroupPlansQuery,
  GetStripeGroupPlansQueryVariables
>
export const GetStripeTopupPlansDocument = gql`
  query getStripeTopupPlans {
    getStripeTopupPlans {
      _id
      name
      displayName
      productId
      priceId
      billingType
      billingPeriod
      credits
      price
      type
    }
  }
`

/**
 * __useGetStripeTopupPlansQuery__
 *
 * To run a query within a React component, call `useGetStripeTopupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeTopupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeTopupPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeTopupPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStripeTopupPlansQuery,
    GetStripeTopupPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetStripeTopupPlansQuery,
    GetStripeTopupPlansQueryVariables
  >(GetStripeTopupPlansDocument, options)
}
export function useGetStripeTopupPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStripeTopupPlansQuery,
    GetStripeTopupPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStripeTopupPlansQuery,
    GetStripeTopupPlansQueryVariables
  >(GetStripeTopupPlansDocument, options)
}
export type GetStripeTopupPlansQueryHookResult = ReturnType<
  typeof useGetStripeTopupPlansQuery
>
export type GetStripeTopupPlansLazyQueryHookResult = ReturnType<
  typeof useGetStripeTopupPlansLazyQuery
>
export type GetStripeTopupPlansQueryResult = Apollo.QueryResult<
  GetStripeTopupPlansQuery,
  GetStripeTopupPlansQueryVariables
>
export const GetSubscriptionPreviewDocument = gql`
  query getSubscriptionPreview($input: SubscriptionPreviewInput!) {
    getSubscriptionPreview(input: $input) {
      status
      amount
      recurringPrice
      billingType
      nextBillDate
    }
  }
`

/**
 * __useGetSubscriptionPreviewQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubscriptionPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSubscriptionPreviewQuery,
    GetSubscriptionPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetSubscriptionPreviewQuery,
    GetSubscriptionPreviewQueryVariables
  >(GetSubscriptionPreviewDocument, options)
}
export function useGetSubscriptionPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSubscriptionPreviewQuery,
    GetSubscriptionPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetSubscriptionPreviewQuery,
    GetSubscriptionPreviewQueryVariables
  >(GetSubscriptionPreviewDocument, options)
}
export type GetSubscriptionPreviewQueryHookResult = ReturnType<
  typeof useGetSubscriptionPreviewQuery
>
export type GetSubscriptionPreviewLazyQueryHookResult = ReturnType<
  typeof useGetSubscriptionPreviewLazyQuery
>
export type GetSubscriptionPreviewQueryResult = Apollo.QueryResult<
  GetSubscriptionPreviewQuery,
  GetSubscriptionPreviewQueryVariables
>
export const CreateVideoVersionDocument = gql`
  mutation createVideoVersion($input: CreateVideoVersionInput!) {
    createVideoVersion(input: $input) {
      ... on VideoVersion {
        _id
        userId
        videoId
        videoTitle
        videoFormat
        videoListPoster
        videoGridPoster
        progress
        url
        renderStatus
        composition
        isPaid
      }
      ... on GeneralResponseWithMessage {
        message
        success
        status
      }
    }
  }
`
export type CreateVideoVersionMutationFn = Apollo.MutationFunction<
  CreateVideoVersionMutation,
  CreateVideoVersionMutationVariables
>

/**
 * __useCreateVideoVersionMutation__
 *
 * To run a mutation, you first call `useCreateVideoVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoVersionMutation, { data, loading, error }] = useCreateVideoVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVideoVersionMutation,
    CreateVideoVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateVideoVersionMutation,
    CreateVideoVersionMutationVariables
  >(CreateVideoVersionDocument, options)
}
export type CreateVideoVersionMutationHookResult = ReturnType<
  typeof useCreateVideoVersionMutation
>
export type CreateVideoVersionMutationResult =
  Apollo.MutationResult<CreateVideoVersionMutation>
export type CreateVideoVersionMutationOptions = Apollo.BaseMutationOptions<
  CreateVideoVersionMutation,
  CreateVideoVersionMutationVariables
>
export const GetVideoVersionsDocument = gql`
  query getVideoVersions($input: VideoVersionsInput) {
    getVideoVersions(input: $input) {
      _id
      userId
      videoId
      videoTitle
      videoFormat
      videoListPoster
      videoGridPoster
      progress
      url
      renderStatus
      composition
      isPaid
      isValid
    }
  }
`

/**
 * __useGetVideoVersionsQuery__
 *
 * To run a query within a React component, call `useGetVideoVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoVersionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVideoVersionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVideoVersionsQuery,
    GetVideoVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetVideoVersionsQuery, GetVideoVersionsQueryVariables>(
    GetVideoVersionsDocument,
    options,
  )
}
export function useGetVideoVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideoVersionsQuery,
    GetVideoVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVideoVersionsQuery,
    GetVideoVersionsQueryVariables
  >(GetVideoVersionsDocument, options)
}
export type GetVideoVersionsQueryHookResult = ReturnType<
  typeof useGetVideoVersionsQuery
>
export type GetVideoVersionsLazyQueryHookResult = ReturnType<
  typeof useGetVideoVersionsLazyQuery
>
export type GetVideoVersionsQueryResult = Apollo.QueryResult<
  GetVideoVersionsQuery,
  GetVideoVersionsQueryVariables
>
export const GetVideoVersionByIdDocument = gql`
  query getVideoVersionById($input: VideoVersionInput!) {
    getVideoVersionById(input: $input) {
      __typename
      ... on VideoVersion {
        _id
        userId
        videoId
        videoTitle
        videoListPoster
        videoGridPoster
        url
        isPaid
        isValid
      }
      ... on GeneralResponseWithMessage {
        message
        success
      }
    }
  }
`

/**
 * __useGetVideoVersionByIdQuery__
 *
 * To run a query within a React component, call `useGetVideoVersionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoVersionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoVersionByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVideoVersionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVideoVersionByIdQuery,
    GetVideoVersionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetVideoVersionByIdQuery,
    GetVideoVersionByIdQueryVariables
  >(GetVideoVersionByIdDocument, options)
}
export function useGetVideoVersionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideoVersionByIdQuery,
    GetVideoVersionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetVideoVersionByIdQuery,
    GetVideoVersionByIdQueryVariables
  >(GetVideoVersionByIdDocument, options)
}
export type GetVideoVersionByIdQueryHookResult = ReturnType<
  typeof useGetVideoVersionByIdQuery
>
export type GetVideoVersionByIdLazyQueryHookResult = ReturnType<
  typeof useGetVideoVersionByIdLazyQuery
>
export type GetVideoVersionByIdQueryResult = Apollo.QueryResult<
  GetVideoVersionByIdQuery,
  GetVideoVersionByIdQueryVariables
>
