import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

const MODE = import.meta.env.MODE
const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN

if (MODE === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [
    //   /^https:\/\/peacock\.io\//, // Matches https://peacock.io/
    //   /^https:\/\/.*\.staging-peacock\.io\//, // Matches any subdomain of staging-peacock.io
    // ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
