import { InMemoryCache } from '@apollo/client'
import pageLimitPagination from './utils/pageLimitPagination'
import { skipFirstPagination } from './utils/skipFirstPagination'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchShutterstockImage: {
          ...pageLimitPagination(),
        },
        searchShutterstockVideo: {
          ...pageLimitPagination(),
        },
        searchShutterstockAudio: {
          ...pageLimitPagination(),
        },
        searchMelodieAudio: {
          ...pageLimitPagination(),
        },
        getGiphyAssets: {
          ...pageLimitPagination(),
        },
        searchUnsplashImage: {
          ...pageLimitPagination(),
        },
        searchAnimations: {
          ...skipFirstPagination(),
        },
        searchFonts: {
          ...skipFirstPagination(),
        },
        searchPeacockVideos: {
          ...skipFirstPagination(),
        },
        searchDfyTemplates: {
          ...skipFirstPagination(),
        },
        searchElementsObjects: {
          ...skipFirstPagination(),
        },
        searchPexelsImage: {
          ...pageLimitPagination(),
        },
        searchPexelsVideo: {
          ...pageLimitPagination(),
        },
        listMedia: {
          ...skipFirstPagination(),
        },
      },
    },
  },
  addTypename: true,
})

export default cache
