import { Route } from 'react-router-dom'

export function NotFound() {
  return <div>Not found</div>
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class NotFoundRoute {
  static Path = '*'

  static Component = (
    <Route path={NotFoundRoute.Path} element={<NotFound />} />
  )
}
