import { lazy } from 'react'
import { Route } from 'react-router-dom'

const Main = lazy(() => import('./scenes/Main'))

export const MainRoutePath = '/:id'
export const MainRouteIndexPath = '/'
export const MainRouteRenderVideoDoneRoute = '/:id/download/:version_id'
export const MainRouteComponent = (
  <>
    <Route path={MainRouteIndexPath} element={<Main />} />
    <Route path={MainRoutePath} element={<Main />} />
    <Route path={MainRouteRenderVideoDoneRoute} element={<Main />} />
  </>
)
