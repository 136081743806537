import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getUserToken = (): string => {
  return cookies.get('sub_user_token') ?? cookies.get('user_token')
}

export const getPecReferrerUrl = () => {
  return cookies.get('pec_referrer_url') || ''
}
